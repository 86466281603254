import React from "react";
import { Router, Route, Redirect, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

import { ConnectedRouter } from "react-router-redux";
import { connect } from "react-redux";

import App from "./containers/App/App";
import asyncComponent from "./helpers/AsyncFunc";
import SignIn from "./containers/Page/SignIn";
import SignUp from "./containers/Page/SignUp";
import UnderMaintenance from "./containers/Page/UnderMaintenance";
import FirstLogin from "./containers/Page/FirstLogin";
import SignupDocument from "./containers/Page/SignupDocument";

const browserHistory = createBrowserHistory();


const RestrictedRoute = ({ component: Component, isLoggedIn, isUnderMaintenance, proceedRegister, proceedFirstLogin, proceedSignupUploadDocument, ...rest }) => (
  
  <Route
    {...rest}
    render={props =>
      isUnderMaintenance ? ( <UnderMaintenance /> ): (
      
        <React.Fragment>
          {isLoggedIn ? ( <Component {...props} /> ) : (

            <React.Fragment>
              {proceedSignupUploadDocument ? ( <SignupDocument /> ) : (

                <React.Fragment>
                  {proceedRegister ? ( <SignUp /> ) : (

                    <React.Fragment>
                      {proceedFirstLogin ? ( <FirstLogin /> ) : (

                        <SignIn />
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )
    }
  />
);

const PublicRoutes = ({ history, isDeviceRegistered, isLoggedIn, isUnderMaintenance, proceedRegister, proceedFirstLogin, proceedSignupUploadDocument }) => {
  
  let routes = (
    <Route
      exact
      path={"/"}
      component={asyncComponent(() => import("./containers/Page/LoadingPage"))}
    />
  );

  console.log("PublicRoutes", {isDeviceRegistered, isLoggedIn, isUnderMaintenance, proceedRegister, proceedFirstLogin, proceedSignupUploadDocument})

  if(isDeviceRegistered || isUnderMaintenance){
    
    routes = (
      <RestrictedRoute path="/" component={App} isLoggedIn={isLoggedIn} isUnderMaintenance={isUnderMaintenance} proceedRegister={proceedRegister} proceedFirstLogin={proceedFirstLogin} proceedSignupUploadDocument={proceedSignupUploadDocument} />
    );
  }

  return (
    <Router history={browserHistory}>
      <ConnectedRouter history={history}>
        <div>
          <Switch>
            {routes}
            <Redirect to="/" />
          </Switch>
        </div>
      </ConnectedRouter>
    </Router>
  );
};

const mapStateToProps = state => {
  
  return {
    isDeviceRegistered: state.Auth.isDeviceRegistered,
    isLoggedIn: state.Auth.isLoggedIn,
    isUnderMaintenance: state.Auth.isUnderMaintenance,
    proceedRegister: state.Auth.proceedRegister,
    proceedFirstLogin: state.Auth.proceedFirstLogin,
    proceedSignupUploadDocument: state.Auth.proceedSignupUploadDocument,
  };
};

export default connect(mapStateToProps)(PublicRoutes);
