import React, { Component } from "react";
import "react-dates/initialize";

// Component

import 'material-design-icons/iconfont/material-icons.css'
import Modal from "../../../components/feedback/modal";


class ModalWarning extends Component {

  render() {
    
    const { visible } = this.props;

    return (
        <Modal
          width={350}
          visible={visible}
          title="Warning"
          onCancel={this.props.onDismiss}
          footer={null}
        >
          Please indicate that you have read and agree to the Terms and Privacy Policy
        </Modal>
    );
  }
}

export default ModalWarning;
