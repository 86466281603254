import { all, takeEvery, put, fork } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import * as actions from "../actions";
import * as API from "../api";
// import { push } from "react-router-redux";
import Helper from "../../helpers/helpers";

const delay = (ms) => new Promise(res => setTimeout(res, ms))


export function* loadPurchaseBillList() {
  
  yield takeEvery(actionTypes.LOAD_PURCHASE_BILL_LIST, function*(action) {

    let val = {dump_recycling: "DR", dump_express: "DE"}

    if(!action.data) action.data = {}

    let service = (action.data.service_type && val[action.data.service_type]) ? val[action.data.service_type] : ""
    let filter_date = (action.data.filter_date) ? action.data.filter_date : ""
    
    let track_no = Helper.generateTrackNo(`PURCHASEBILLLIST${service}${action.data.offset}${filter_date}`);
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.loadPurchaseBillList(action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
          
          let params = {
            data: response.data.data,
            offset: action.data.offset,
            limit: action.data.limit,
            total_result: response.data.data.length,
            total: response.data.total,
          }
          
          yield put(actions.loadPurchaseBillListSuccess(params));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.loadPurchaseBillListFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.loadPurchaseBillListFail());
    }
  });
}


export function* getPurchaseBillDumpExpress() {
  
  yield takeEvery(actionTypes.GET_PURCHASE_BILL_DUMP_EXPRESS, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("PURCHASEBILLDE");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.getPurchaseBillDumpExpress(action.id, action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
      
          yield put(actions.getPurchaseBillDumpExpressSuccess(response.data.data));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.getPurchaseBillDumpExpressFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.getPurchaseBillDumpExpressFail());
    }
  });
}


export function* getPurchaseBillDumpRecycling() {
  
  yield takeEvery(actionTypes.GET_PURCHASE_BILL_DUMP_RECYCLING, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("PURCHASEBILLDR");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.getPurchaseBillDumpRecycling(action.id, action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
      
          yield put(actions.getPurchaseBillDumpRecyclingSuccess(response.data.data));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.getPurchaseBillDumpRecyclingFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.getPurchaseBillDumpRecyclingFail());
    }
  });
}

export function* loadStoreReceiptList() {
  
  yield takeEvery(actionTypes.LOAD_STORE_RECEIPT_LIST, function*(action) {

    let val = {dump_recycling: "DR", dump_express: "DE"}

    if(!action.data) action.data = {}

    let service = (action.data.service_type && val[action.data.service_type]) ? val[action.data.service_type] : ""
    let filter_date = (action.data.filter_date) ? action.data.filter_date : ""

    let track_no = Helper.generateTrackNo(`STORERECEIPTLIST${service}${action.data.offset}${filter_date}`);

    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.loadStoreReceiptList(action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
          
          let params = {
            data: response.data.data,
            offset: action.data.offset,
            limit: action.data.limit,
            total_result: response.data.data.length,
            total: response.data.total,
          }
          
          yield put(actions.loadStoreReceiptListSuccess(params));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.loadStoreReceiptListFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.loadStoreReceiptListFail());
    }
  });
}


export function* getStoreReceiptDumpExpress() {
  
  yield takeEvery(actionTypes.GET_STORE_RECEIPT_DUMP_EXPRESS, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("STORERECEIPTDE");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.getStoreReceiptDumpExpress(action.id, action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
      
          yield put(actions.getStoreReceiptDumpExpressSuccess(response.data.data));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.getStoreReceiptDumpExpressFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.getStoreReceiptDumpExpressFail());
    }
  });
}


export function* getStoreReceiptDumpRecycling() {
  
  yield takeEvery(actionTypes.GET_STORE_RECEIPT_DUMP_RECYCLING, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("STORERECEIPTDR");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.getStoreReceiptDumpRecycling(action.id, action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
      
          yield put(actions.getStoreReceiptDumpRecyclingSuccess(response.data.data));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.getStoreReceiptDumpRecyclingFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.getStoreReceiptDumpRecyclingFail());
    }
  });
}

export function* loadWasteReceiptList() {
  
  yield takeEvery(actionTypes.LOAD_WASTE_RECEIPT_LIST, function*(action) {

    if(!action.data) action.data = {}

    let filter_date = (action.data.filter_date) ? action.data.filter_date : ""

    let track_no = Helper.generateTrackNo(`WASTERECEIPTLIST${action.data.offset}${filter_date}`);

    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.loadWasteReceiptList(action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
          
          let params = {
            data: response.data.data,
            offset: action.data.offset,
            limit: action.data.limit,
            total_result: response.data.data.length,
            total: response.data.total,
          }
          
          yield put(actions.loadWasteReceiptListSuccess(params));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.loadWasteReceiptListFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.loadWasteReceiptListFail());
    }
  });
}


export function* getWasteReceiptDumpWaste() {
  
  yield takeEvery(actionTypes.GET_WASTE_RECEIPT_DUMP_WASTE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("WASTERECEIPTDW");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.getWasteReceiptDumpWaste(action.id, action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
      
          yield put(actions.getWasteReceiptDumpWasteSuccess(response.data.data));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.getWasteReceiptDumpWasteFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.getWasteReceiptDumpWasteFail());
    }
  });
}


export function* loadPurchaseBillMonth() {
  
  yield takeEvery(actionTypes.LOAD_PURCHASE_BILL_MONTH, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("PURCHASEBILLMONTH");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.loadPurchaseBillMonth(action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
                  
          yield put(actions.loadPurchaseBillMonthSuccess(response.data.data));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.loadPurchaseBillMonthFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.loadPurchaseBillMonthFail());
    }
  });
}


export function* getPurchaseBillSummary() {
  
  yield takeEvery(actionTypes.LOAD_PURCHASE_BILL_SUMMARY, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("PURCHASEBILLSUMMARY");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.getPurchaseBillSummary(action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
      
          yield put(actions.getPurchaseBillSummarySuccess(response.data.data));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.getPurchaseBillSummaryFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.getPurchaseBillSummaryFail());
    }
  });
}


export function* loadStockRecordSummary() {
  
  yield takeEvery(actionTypes.LOAD_STOCK_RECORD_SUMMARY, function*(action) {

    if(!action.data) action.data = {}

    let record_type = (action.data.record_type) ? action.data.record_type : ""

    let track_no = Helper.generateTrackNo(`STOCKRECORDSUMMARY${record_type}`);
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.loadStockRecordSummary(action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
      
          yield put(actions.loadStockRecordSummarySuccess(response.data.data));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.loadStockRecordSummaryFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.loadStockRecordSummaryFail());
    }
  });
}


export function* loadStockRecordList() {
  
  yield takeEvery(actionTypes.LOAD_STOCK_RECORD_LIST, function*(action) {

    if(!action.data) action.data = {}

    let record_type = (action.data.record_type) ? action.data.record_type : ""
    
    let track_no = Helper.generateTrackNo(`STORERECORDLIST${record_type}${action.data.offset}`);

    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.loadStockRecordList(action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
          
          let params = {
            data: response.data.data,
            offset: action.data.offset,
            limit: action.data.limit,
            total_result: response.data.data.length,
            total: response.data.total,
          }
          
          yield put(actions.loadStockRecordListSuccess(params));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.loadStockRecordListFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.loadStockRecordListFail());
    }
  });
}


export function* loadMonthlyStockReport() {
  
  yield takeEvery(actionTypes.LOAD_MONTHLY_STOCK_REPORT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("MONTHLYSTOCKREPORT");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.loadMonthlyStockReport(action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
      
          yield put(actions.loadMonthlyStockReportSuccess(response.data.data));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.loadMonthlyStockReportFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.loadMonthlyStockReportFail());
    }
  });
}



export function* getMonthlyStockReportDoc() {
  
  yield takeEvery(actionTypes.GET_MONTHLY_STOCK_REPORT_DOC, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("MONTHLYSTOCKREPORTDOC");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.getMonthlyStockReportDoc(action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
      
          Helper.loadSecureFile(response.data.data, file => {

            let filename = `Monthly Stock Summary Report ${action.data.period}`;
            let filetype = "xlsx"

            var downloadLink = document.createElement("a");
            downloadLink.href = file;
            downloadLink.download = `${filename}.${filetype}`;

            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          });

          yield put(actions.getMonthlyStockReportDocSuccess());

        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.getMonthlyStockReportDocFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.getMonthlyStockReportDocFail());
    }
  });
}


export function* loadMonthlyWasteReport() {
  
  yield takeEvery(actionTypes.LOAD_MONTHLY_WASTE_REPORT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("MONTHLYWASTEREPORT");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.loadMonthlyWasteReport(action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
      
          yield put(actions.loadMonthlyWasteReportSuccess(response.data.data));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.loadMonthlyWasteReportFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.loadMonthlyWasteReportFail());
    }
  });
}



export function* getMonthlyWasteReportDoc() {
  
  yield takeEvery(actionTypes.GET_MONTHLY_WASTE_REPORT_DOC, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("MONTHLYWASTEREPORTDOC");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.getMonthlyWasteReportDoc(action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
      
          Helper.loadSecureFile(response.data.data, file => {

            let filename = `Monthly Waste Summary Report ${action.data.period}`;
            let filetype = "xlsx"

            var downloadLink = document.createElement("a");
            downloadLink.href = file;
            downloadLink.download = `${filename}.${filetype}`;

            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          });

          yield put(actions.getMonthlyWasteReportDocSuccess());

        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.getMonthlyWasteReportDocFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.getMonthlyWasteReportDocFail());
    }
  });
}


export function* dailySettlementDetail() {
  
  yield takeEvery(actionTypes.LOAD_DAILY_SETTLEMENT_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DAILYSETTLEMENTDETAIL");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.dailySettlementDetail(action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
      
          yield put(actions.dailySettlementDetailSuccess(response.data.data));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.dailySettlementDetailFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.dailySettlementDetailFail());
    }
  });
}

export function* completeDailySettlement() {
  yield takeEvery(actionTypes.COMPLETE_DAILY_SETTLEMENT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("UPDDAILYSETTLEMENT");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.completeDailySettlement(action.data);
      if(response)
      {
        if (response.data.success) {
          
          yield delay(1000);
          yield put(actions.completeDailySettlementSuccess());
          
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.completeDailySettlementFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.completeDailySettlementFail());
    }
  });
}



export default function* reportSaga() {
  
  yield all([fork(loadPurchaseBillList)]);
  yield all([fork(getPurchaseBillDumpExpress)]);
  yield all([fork(getPurchaseBillDumpRecycling)]);
  yield all([fork(loadStoreReceiptList)]);
  yield all([fork(getStoreReceiptDumpExpress)]);
  yield all([fork(loadWasteReceiptList)]);
  yield all([fork(getStoreReceiptDumpRecycling)]);
  yield all([fork(getWasteReceiptDumpWaste)]);
  yield all([fork(loadPurchaseBillMonth)]);
  yield all([fork(getPurchaseBillSummary)]);
  yield all([fork(loadStockRecordSummary)]);
  yield all([fork(loadStockRecordList)]);
  yield all([fork(loadMonthlyStockReport)]);
  yield all([fork(getMonthlyStockReportDoc)]);
  yield all([fork(loadMonthlyWasteReport)]);
  yield all([fork(getMonthlyWasteReportDoc)]);
  yield all([fork(dailySettlementDetail)]);
  yield all([fork(completeDailySettlement)]);
}
