import Auth from "./auth_reducer";
import App from "./app_reducer";
import Grader from "./grader_reducer";
import GoogleMap from "./google_map_reducer";
import General from "./general_reducer";
import Zone from "./zone_reducer";
import Order from "./order_reducer";
import Report from "./report_reducer";

export default {
  Auth,
  App,
  Grader,
  GoogleMap,
  General,
  Zone,
  Order,
  Report,
};