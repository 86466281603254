import * as actionTypes from "../actions/actionTypes";
import { cloneDeep } from "lodash";

const initState = {
  zoneCount: {},
  isLoadingZoneCount: false,
  zoneList: {
    data: [],
    offset: -1,
    limit: 10,
    length: 0,
    total: 0,
    isLoading: false,
    hasMore: false,
  },
  isLoadingZone: false,
  isRefreshingZoneList: false,
  isLoadingZoneUpdate: false,  

  zoneDetail: null,
  isLoadingZoneDetail: false,

  zoneCurrentStock: null,
  isLoadingZoneCurrentStock: false,

  isCreateZoneSuccess: false,
  isCreateZoneFailed: false,
  isProcessCreateZone: false,

  isUpdateZoneSuccess: false,
  isUpdateZoneFailed: false,
  isProcessUpdateZone: false,

  isRemoveZoneSuccess: false,
  isProcessRemoveZone: false,

  isUpdateZonePlacementSuccess: false,
  isUpdateZonePlacementFailed: false,
  isProcessUpdateZonePlacement: false,

  isRemoveZonePlacementSuccess: false,
  isRemoveZonePlacementFailed: false,
  isProcessRemoveZonePlacement: false,

  isUpdateZoneFullyFilledSuccess: false,
  isUpdateZoneFullyFilledFailed: false,
  isProcessUpdateZoneFullyFilled: false,

  dumpsterRecyclingList: {},
  dumpsterRecyclingWeight: {},
  isLoadingDumpsterRecyclingList: true,

  isUpdateZoneCollectionSuccess: false,
  isUpdateZoneCollectionFailed: false,
  isProcessUpdateZoneCollection: false,

  
  isLoadingDumpHistory: false,
  isLoadDumpHistoryDone: false,
  isRefreshingDumpHistory: false,
  dumpHistoryList: {
    data: [],
    offset: -1,
    limit: 10,
    length: 0,
    total: 0,
    isLoading: false,
    hasMore: false,
  },
  dumpHistoryListFilter: {},
  expressPackingList: {},
  isProcessLoadExpressPackingList: false,
  isGenerateRecyclingPackingListSuccess: {status: false, packingID: null},
  isGenerateRecyclingPackingListFailed: false,
  isProcessGenerateRecyclingPackingList: false,
  recyclingPackingHistoryList: {
    data: [],
    offset: -1,
    limit: 10,
    length: 0,
    total: 0,
    isLoading: false,
    hasMore: false,
  },
  recyclingPackingHistoryListFilter: {},
  isRefreshingRecyclingPackingHistory: false,
  isLoadingRecyclingPackingHistory: false,
  isLoadRecyclingPackingHistoryDone: false,
  recyclingPackingList: {},
  isProcessLoadRecyclingPackingList: false,
};

export default function ZoneReducer(state = initState, action) {

  switch (action.type) {

    
    case actionTypes.LOAD_ZONE_COUNT:{

      return {
        ...state,
        zoneCount: {},
        isLoadingZoneCount: true,
      };
    }

    case actionTypes.LOAD_ZONE_COUNT_SUCCESS: {

      return {
        ...state,
        zoneCount: action.data,
        isLoadingZoneCount: false,
      };
    }

    case actionTypes.LOAD_ZONE_COUNT_FAIL:{
      return {
        ...state,
        isLoadingZoneCount: false,
      };
    }


    case actionTypes.LOAD_ZONE_LIST:{
      let zoneList = cloneDeep(state.zoneList);
  
      zoneList.isLoading = true;

      if (action.data.offset === 0) {
        zoneList.data = [];
      }
      
      return {
        ...state,
        isLoadingZone: true,
        zoneList,
        isRefreshingZoneList: (action.data.offset > 0) ? true : false
      };
    }

    case actionTypes.LOAD_ZONE_LIST_SUCCESS: {
      let zoneList = {};
      
      if (action.data.limit === undefined) {
        zoneList = cloneDeep(state.zoneList);
        zoneList.data = action.data.data;
      } else {
        zoneList.offset = -1;

        // First time load / Refresh
        if (action.data.offset === 0) {
          zoneList.data = action.data.data;
          zoneList.length = action.data.total_result;
        } else {
          if (action.data.total_result === 0) {
            zoneList.data = [...state.zoneList.data];
            zoneList.offset = action.data.offset;
          } else {
            let temp = [...state.zoneList.data];
            let old_key = Object.keys(temp);
            Object.keys(action.data.data).forEach((val) => {
              if (old_key.includes(val)) {
                temp.push(action.data.data[val]);
              }
            });

            zoneList.data = temp;
            zoneList.length = state.zoneList.length + action.data.total_result;
          }
        }
        if (action.data.total_result < action.data.limit) {
          zoneList.offset = zoneList.length;
        }
      }
  
      zoneList.isLoading = false;
      zoneList.total = action.data.total;
      zoneList.hasMore = false;
      
      if(zoneList.length < zoneList.total)
      {
        zoneList.hasMore = true;
      }

      return {
        ...state,
        isLoadingZone: false,
        isLoadingZoneUpdate: false,
        zoneList
      };
    }

    case actionTypes.LOAD_ZONE_LIST_FAIL:{
      let zoneList = cloneDeep(state.zoneList);
      zoneList.isLoading = false;

      return {
        ...state,
        zoneList,
        isLoadingZone: false,
      };
    }



    case actionTypes.LOAD_ZONE_DETAIL: {
      return {
        ...state,
        zoneDetail: null,
        isLoadingZoneDetail: true,
      };
    }

    case actionTypes.LOAD_ZONE_DETAIL_SUCCESS: {
      return {
        ...state,
        zoneDetail: action.data,
        isLoadingZoneDetail: false,
      };
    }

    case actionTypes.LOAD_ZONE_DETAIL_FAIL: {
      return {
        ...state,
        isLoadingZoneDetail: false,
      };
    }


    case actionTypes.LOAD_ZONE_CURRENT_STOCK_DR: {
      return {
        ...state,
        zoneCurrentStock: null,
        isLoadingZoneCurrentStock: true,
      };
    }

    case actionTypes.LOAD_ZONE_CURRENT_STOCK_DR_SUCCESS: {
      return {
        ...state,
        zoneCurrentStock: action.data,
        isLoadingZoneCurrentStock: false,
      };
    }

    case actionTypes.LOAD_ZONE_CURRENT_STOCK_DR_FAIL: {
      return {
        ...state,
        isLoadingZoneCurrentStock: false,
      };
    }
    


    case actionTypes.CREATE_ZONE: {
      return {
        ...state,
        isCreateZoneSuccess: false,
        isCreateZoneFailed: false,
        isProcessCreateZone: true,
      };
    }

    case actionTypes.CREATE_ZONE_SUCCESS: {
      return {
        ...state,
        isCreateZoneSuccess: true,
        isProcessCreateZone: false,
      };
    }

    case actionTypes.CREATE_ZONE_FAIL: {
      return {
        ...state,
        isCreateZoneFailed: true,
        isProcessCreateZone: false,
      };
    }


    case actionTypes.UPDATE_ZONE: {
      return {
        ...state,
        isUpdateZoneSuccess: false,
        isUpdateZoneFailed: false,
        isProcessUpdateZone: true,
      };
    }

    case actionTypes.UPDATE_ZONE_SUCCESS: {
      return {
        ...state,
        isUpdateZoneSuccess: true,
        isProcessUpdateZone: false,
      };
    }

    case actionTypes.UPDATE_ZONE_FAIL: {
      return {
        ...state,
        isUpdateZoneFailed: true,
        isProcessUpdateZone: false,
      };
    }


    case actionTypes.REMOVE_ZONE: {
      return {
        ...state,
        isRemoveZoneSuccess: false,
        isProcessRemoveZone: true,
      };
    }

    case actionTypes.REMOVE_ZONE_SUCCESS: {
      return {
        ...state,
        isRemoveZoneSuccess: true,
        isProcessRemoveZone: false,
      };
    }

    case actionTypes.REMOVE_ZONE_FAIL: {
      return {
        ...state,
        isRemoveZoneSuccess: false,
        isProcessRemoveZone: false,
      };
    }


    case actionTypes.UPDATE_ZONE_PLACEMENT: {
      return {
        ...state,
        isUpdateZonePlacementSuccess: false,
        isUpdateZonePlacementFailed: false,
        isProcessUpdateZonePlacement: true,
      };
    }

    case actionTypes.UPDATE_ZONE_PLACEMENT_SUCCESS: {
      return {
        ...state,
        isUpdateZonePlacementSuccess: true,
        isProcessUpdateZonePlacement: false,
      };
    }

    case actionTypes.UPDATE_ZONE_PLACEMENT_FAIL: {
      return {
        ...state,
        isUpdateZonePlacementFailed: true,
        isProcessUpdateZonePlacement: false,
      };
    }


    case actionTypes.REMOVE_ZONE_PLACEMENT: {
      return {
        ...state,
        isRemoveZonePlacementSuccess: false,
        isRemoveZonePlacementFailed: false,
        isProcessRemoveZonePlacement: true,
      };
    }

    case actionTypes.REMOVE_ZONE_PLACEMENT_SUCCESS: {
      return {
        ...state,
        isRemoveZonePlacementSuccess: true,
        isProcessRemoveZonePlacement: false,
      };
    }

    case actionTypes.REMOVE_ZONE_PLACEMENT_FAIL: {
      return {
        ...state,
        isRemoveZonePlacementFailed: true,
        isProcessRemoveZonePlacement: false,
      };
    }
    
    case actionTypes.UPDATE_ZONE_FULLY_FILLED: {
      return {
        ...state,
        isUpdateZoneFullyFilledSuccess: false,
        isUpdateZoneFullyFilledFailed: false,
        isProcessUpdateZoneFullyFilled: true,
      };
    }

    case actionTypes.UPDATE_ZONE_FULLY_FILLED_SUCCESS: {
      return {
        ...state,
        isUpdateZoneFullyFilledSuccess: true,
        isProcessUpdateZoneFullyFilled: false,
      };
    }

    case actionTypes.UPDATE_ZONE_FULLY_FILLED_FAIL: {
      return {
        ...state,
        isUpdateZoneFullyFilledFailed: true,
        isProcessUpdateZoneFullyFilled: false,
      };
    }


    case actionTypes.LOAD_EXPRESS_DUMPSTER_RECYCLING_LIST: {
      return {
        ...state,
        dumpsterRecyclingList: {},
        dumpsterRecyclingWeight: {},
        isLoadingDumpsterRecyclingList: true,
      };
    }

    case actionTypes.LOAD_EXPRESS_DUMPSTER_RECYCLING_LIST_SUCCESS: {

      return {
        ...state,
        dumpsterRecyclingList: action.data.data,
        dumpsterRecyclingWeight: action.data.weight,
        isLoadingDumpsterRecyclingList: false,
      };
    }

    case actionTypes.LOAD_EXPRESS_DUMPSTER_RECYCLING_LIST_FAIL: {
      return {
        ...state,
        isLoadingDumpsterRecyclingList: false,
      };
    }

    
    case actionTypes.UPDATE_ZONE_COLLECTION: {
      return {
        ...state,
        isUpdateZoneCollectionSuccess: false,
        isUpdateZoneCollectionFailed: false,
        isProcessUpdateZoneCollection: true,
      };
    }

    case actionTypes.UPDATE_ZONE_COLLECTION_SUCCESS: {
      return {
        ...state,
        isUpdateZoneCollectionSuccess: true,
        isProcessUpdateZoneCollection: false,
      };
    }

    case actionTypes.UPDATE_ZONE_COLLECTION_FAIL: {
      return {
        ...state,
        isUpdateZoneCollectionFailed: true,
        isProcessUpdateZoneCollection: false,
      };
    }

    
    

    case actionTypes.LOAD_DUMPSTER_HISTORY_LIST:{
      let dumpHistoryList = cloneDeep(state.dumpHistoryList);

      dumpHistoryList.isLoading = true;
  
      if (action.data.offset === 0) {
        dumpHistoryList.data = [];
      }

      return {
        ...state,
        isLoadingDumpHistory: true,
        isLoadDumpHistoryDone: false,
        dumpHistoryList,
        isRefreshingDumpHistory: (action.data.offset > 0) ? true : false,
        dumpHistoryListFilter: action.data
      };
    }

    case actionTypes.LOAD_DUMPSTER_HISTORY_LIST_SUCCESS: {
      let dumpHistoryList = {};
      
      if (action.data.limit === undefined) {
        dumpHistoryList = cloneDeep(state.dumpHistoryList);
        dumpHistoryList.data = action.data.data;
      } else {
        dumpHistoryList.offset = -1;

        // First time load / Refresh
        if (action.data.offset === 0) {
          dumpHistoryList.data = action.data.data;
          dumpHistoryList.length = action.data.total_result;
        } else {
          if (action.data.total_result === 0) {
            dumpHistoryList.data = [...state.dumpHistoryList.data];
            dumpHistoryList.offset = action.data.offset;
          } else {
            let temp = [...state.dumpHistoryList.data];
            let old_key = Object.keys(temp);
            Object.keys(action.data.data).forEach((val) => {
              if (old_key.includes(val)) {
                temp.push(action.data.data[val]);
              }
            });

            dumpHistoryList.data = temp;
            dumpHistoryList.length = state.dumpHistoryList.length + action.data.total_result;
          }
        }
        if (action.data.total_result < action.data.limit) {
          dumpHistoryList.offset = dumpHistoryList.length;
        }
      }
  
      dumpHistoryList.isLoading = false;
      dumpHistoryList.total = action.data.total;
      dumpHistoryList.hasMore = false;
      
      if(dumpHistoryList.length < dumpHistoryList.total)
      {
        dumpHistoryList.hasMore = true;
      }

      return {
        ...state,
        isLoadingDumpHistory: false,
        isLoadDumpHistoryDone: true,
        dumpHistoryList
      };
    }

    case actionTypes.LOAD_DUMPSTER_HISTORY_LIST_FAIL:{
      let dumpHistoryList = cloneDeep(state.dumpHistoryList);
      dumpHistoryList.isLoading = false;

      return {
        ...state,
        dumpHistoryList,
        isLoadingDumpHistory: false,
        isLoadDumpHistoryDone: true,
      };
    }



    case actionTypes.LOAD_EXPRESS_PACKING_LIST: {
      return {
        ...state,
        expressPackingList: {},
        isProcessLoadExpressPackingList: true,
      };
    }

    case actionTypes.LOAD_EXPRESS_PACKING_LIST_SUCCESS: {
      return {
        ...state,
        expressPackingList: action.data,
        isProcessLoadExpressPackingList: false,
      };
    }

    case actionTypes.LOAD_EXPRESS_PACKING_LIST_FAIL: {
      return {
        ...state,
        isProcessLoadExpressPackingList: false,
      };
    }
    

    case actionTypes.GENERATE_RECYCLING_PACKING_LIST: {
      return {
        ...state,
        isGenerateRecyclingPackingListSuccess: {status: false, packingID: null},
        isGenerateRecyclingPackingListFailed: false,
        isProcessGenerateRecyclingPackingList: true,
      };
    }

    case actionTypes.GENERATE_RECYCLING_PACKING_LIST_SUCCESS: {
      
      return {
        ...state,
        isGenerateRecyclingPackingListSuccess: {status: true, packingID: action.data.packing_id},
        isProcessGenerateRecyclingPackingList: false,
      };
    }

    case actionTypes.GENERATE_RECYCLING_PACKING_LIST_FAIL: {
      return {
        ...state,
        isGenerateRecyclingPackingListFailed: true,
        isProcessGenerateRecyclingPackingList: false,
      };
    }
  

    case actionTypes.LOAD_RECYCLING_PACKING_HISTORY_LIST:{
      let recyclingPackingHistoryList = cloneDeep(state.recyclingPackingHistoryList);

      recyclingPackingHistoryList.isLoading = true;

      if (action.data.offset === 0) {
        recyclingPackingHistoryList.data = [];
      }

      return {
        ...state,
        isLoadingRecyclingPackingHistory: true,
        isLoadRecyclingPackingHistoryDone: false,
        recyclingPackingHistoryList,
        isRefreshingRecyclingPackingHistory: (action.data.offset > 0) ? true : false,
        recyclingPackingHistoryListFilter: action.data
      };
    }

    case actionTypes.LOAD_RECYCLING_PACKING_HISTORY_LIST_SUCCESS: {
      let recyclingPackingHistoryList = {};
      
      if (action.data.limit === undefined) {
        recyclingPackingHistoryList = cloneDeep(state.recyclingPackingHistoryList);
        recyclingPackingHistoryList.data = action.data.data;
      } else {
        recyclingPackingHistoryList.offset = -1;

        // First time load / Refresh
        if (action.data.offset === 0) {
          recyclingPackingHistoryList.data = action.data.data;
          recyclingPackingHistoryList.length = action.data.total_result;
        } else {
          if (action.data.total_result === 0) {
            recyclingPackingHistoryList.data = [...state.recyclingPackingHistoryList.data];
            recyclingPackingHistoryList.offset = action.data.offset;
          } else {
            let temp = [...state.recyclingPackingHistoryList.data];
            let old_key = Object.keys(temp);
            Object.keys(action.data.data).forEach((val) => {
              if (old_key.includes(val)) {
                temp.push(action.data.data[val]);
              }
            });

            recyclingPackingHistoryList.data = temp;
            recyclingPackingHistoryList.length = state.recyclingPackingHistoryList.length + action.data.total_result;
          }
        }
        if (action.data.total_result < action.data.limit) {
          recyclingPackingHistoryList.offset = recyclingPackingHistoryList.length;
        }
      }
    
      recyclingPackingHistoryList.isLoading = false;
      recyclingPackingHistoryList.total = action.data.total;
      recyclingPackingHistoryList.hasMore = false;
      
      if(recyclingPackingHistoryList.length < recyclingPackingHistoryList.total)
      {
        recyclingPackingHistoryList.hasMore = true;
      }
      
      return {
        ...state,
        isLoadingRecyclingPackingHistory: false,
        isLoadRecyclingPackingHistoryDone: true,
        recyclingPackingHistoryList
      };
    }

    case actionTypes.LOAD_RECYCLING_PACKING_HISTORY_LIST_FAIL:{
      let recyclingPackingHistoryList = cloneDeep(state.recyclingPackingHistoryList);
      recyclingPackingHistoryList.isLoading = false;

      return {
        ...state,
        recyclingPackingHistoryList,
        isLoadingRecyclingPackingHistory: false,
        isLoadRecyclingPackingHistoryDone: true,
      };
    }

    case actionTypes.LOAD_RECYCLING_PACKING_HISTORY_DETAIL: {
      return {
        ...state,
        recyclingPackingList: {},
        isProcessLoadRecyclingPackingList: true,
      };
    }

    case actionTypes.LOAD_RECYCLING_PACKING_HISTORY_DETAIL_SUCCESS: {
      return {
        ...state,
        recyclingPackingList: action.data,
        isProcessLoadRecyclingPackingList: false,
      };
    }

    case actionTypes.LOAD_RECYCLING_PACKING_HISTORY_DETAIL_FAIL: {
      return {
        ...state,
        isProcessLoadRecyclingPackingList: false,
      };
    }


    default:
      return state;
  }
}
