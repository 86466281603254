import axios from "axios";

import { GOOGLE_API_KEY, CROSS_ORIGIN } from "../../settings";
import { store } from "../store";

// Base URL
export const baseURL = () => {
  return `${CROSS_ORIGIN}/https://maps.googleapis.com/maps/api`;
};

export const loadGooglePlaceAutocomplete = value => {
  let env = store.getState().App.env;
  const url = `${baseURL()}/place/autocomplete/json?input=${value}&key=${GOOGLE_API_KEY[env]}&region=my`;
  return axios.get(url);
};
