const Color = {
  Primary: "#56B46C",
  Secondary: "#04954D",
  Tertiary: "#DFECE4",

  //  orange
  Flamingo: "#f05b22",
  FlushOrange: "#ff7300",
  SoftOrange: "#fec193",
  PaleOrange: "#ffeddf",
  Seashell: "#fff5ee",
  ClearOrange: "#EF8B03", //
  SicilianVilla: "#FCD094", //

  // Yellow
  Amber: "#FFC200",
  LightYellow: "#eee1b1",
  Khaki: "#bdad73",
  GreyYellow: "#c5c47a",
  
  // Green
  Camarone: "#07624A", //
  Salem: "#016F52", //
  MacaronGreen: "#9ED5B9",  //
  SnowBallet: "#ECF7F0",  //
  FunGreen: "#009627",
  Jade: "#00B24D",
  Malachite: "#00C028",
  Caribbean: "#02c498",
  Green: "#008000",
  LightGreen: "#b1eee1",
  GreyGreen: "#75a99e",

  // red
  Carnation: "#ef4c66",
  BrinkPink: "#F75D81",
  LightPink: "#eeb1bf",
  PinkPearl: "#a86b79",
  GreyRed: "#c18f9b",
  RoseRed: '#ef183b',
  GreyPink: "#c191ba",
  CoralRed: "#FF3D40", //
  PomeloSugar: "#FCE5E5", //
  PeachCream: "#FFEFD9", //

  // grey
  Black: "#000000",
  GreyBlack: "#222222",  
  RavenBlack: "#3C3C3C", //
  Thunder: "#2f2528",
  MineShaft: "#333333",
  Scorpion: "#595959",
  Boulderapprox: "#707070",  //
  Dusty: "#979797",
  // DustyGrey: "#9B9B9B", //
  DustyGrey: "#7b7b7b",
  Nobel: "#BABABA", //
  Alto: "#D9D9D9", //
  Mercury: "#EBEBEB", //
  Sand: "#F7F7F7", //
  TransparentGrey: "#8080801f",
  Grey: "#ededed",
  DarkGrey: "#3e3e3e",
  Beige: "#f5f5dc",

  // white
  White: "#FCFCFC", //

  // blue
  PrussianBlue: "#003859",
  CongressBlue: "#024099",
  Lochmara: "#007DD5",
  Viking: "#5BC0DE",
  DarkBlue: "#108ee9",
  LinghtBlue: "#72c2e0",
  BlueLink: "#1890ff",
  GreyBlue: "#6790ad",

  // purple
  Plum: "#70266E",
  DaisyBush: "#42299a",
  PurpleBlue: "#aa9ae4",
  Purple: "#7266BA",
  GreyPurple: "#8680b1",
};

export default Color;
