import styled from "styled-components";
import { palette } from "styled-theme";
import WithDirection from "../../../settings/withDirection";
import { borderRadius } from "../../../settings/style-util";
import Color from "../../../assets/Color";


const SignUpStyleWrapper = styled.div`
  border:1px solid rgb(255 255 255 / 0%);
  width: 100%;
  min-height: 100vh;

  .ant-input-group .ant-input{
    text-align: left;
  }

  .isoLoginContentWrapper {
    margin: 80px 10px 0 10px;
  }

  .isoLoginContent {
    font-size: 19px;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    padding: 25px;
    position: relative;

    @media only screen and (max-width: 767px) {
      width: 100%;
      padding: 20px 20px;
    }

    .isoLogoWrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-shrink: 0;

      a {
        font-size: 24px;
        font-weight: 300;
        line-height: 1;
        text-transform: uppercase;
        color: ${palette("secondary", 2)};
      }
    }

    .isoSignInForm {
      width: 100%;
      display: flex;
      flex-shrink: 0;
      flex-direction: column;
      padding-bottom: 100px;

      .isoInputWrapper {
        margin-bottom: 15px;
        text-align: center;
        display: block;
        &:last-of-type {
          margin-bottom: 0;
        }

        .ant-input-group{
          margin-bottom: 0px;

          .ant-select{
            width: 100%
          }
        }

        .ant-form-item{
          margin-bottom: 0px;
          padding-bottom:0;

          .ant-form-item-label label{
            font-size: 10px;
          }
          input {
            &::-webkit-input-placeholder {
              color: ${palette("grayscale", 0)};
            }

            &:-moz-placeholder {
              color: ${palette("grayscale", 0)};
            }

            &::-moz-placeholder {
              color: ${palette("grayscale", 0)};
            }
            &:-ms-input-placeholder {
              color: ${palette("grayscale", 0)};
            }
          }
        }

        input[type="password"]{
          width: 100%
        }
        }

      .isoHelperText {
        font-size: 12px;
        font-weight: 400;
        line-height: 1.2;
        color: ${palette("grayscale", 1)};
        padding-left: ${props =>
          props["data-rtl"] === "rtl" ? "inherit" : "13px"};
        padding-right: ${props =>
          props["data-rtl"] === "rtl" ? "13px" : "inherit"};
        margin: 15px 0;
        position: relative;
        display: flex;
        align-items: center;

        &:before {
          content: "*";
          color: ${palette("error", 0)};
          padding-right: 3px;
          font-size: 14px;
          line-height: 1;
          position: absolute;
          top: 2px;
          left: ${props => (props["data-rtl"] === "rtl" ? "inherit" : "0")};
          right: ${props => (props["data-rtl"] === "rtl" ? "0" : "inherit")};
        }
      }

      .isoHelperWrapper {
        margin-top: 25px;
        flex-direction: column;

        .isoForgotPass{
          cursor: pointer;
            font-size:12px;
            text-transform: uppercase;
            color: ${palette("text", 3)};
            &:hover{
              color:${Color.SoftOrange}
            }
        }
      }

      .isoOtherLogin {
        padding-top: 40px;
        margin-top: 35px;
        border-top: 1px dashed ${palette("grayscale", 2)};

        > a {
          display: flex;
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        button {
          width: 100%;
          height: 42px;
          border: 0;
          font-weight: 500;


          &.btnFacebook {
            background-color: #3b5998;

            &:hover {
              background-color: darken(#3b5998, 5%);
            }
          }

          &.btnGooglePlus {
            background-color: #dd4b39;
            margin-top: 15px;

            &:hover {
              background-color: darken(#dd4b39, 5%);
            }
          }

          &.btnAuthZero {
            background-color: #e14615;
            margin-top: 15px;

            &:hover {
              background-color: darken(#e14615, 5%);
            }
          }

          &.btnFirebase {
            background-color: ${palette("color", 5)};
            margin-top: 15px;

            &:hover {
              background-color: ${palette("color", 6)};
            }
          }
        }
      }

      .width40{
        width: 40%
      }

      .width18{
        width: 18%
      }

     .width30{
       width: 30%
     }

     .width68{
      width: 68%
     }

     .ml2{
       margin-left: 2%;
     }

     .mt25px{
      margin-top: 5%;
     }
    }
  }

  .form-checkbox {
    padding-right: 4%;

    .checkbox-outline {        
      width: 18px;
      height: 18px;
      ${borderRadius("50%")};
      border: 1px solid ${Color.Nobel};
      text-align: center;
    }
    .checkbox-outline.checked {
      background: ${Color.Primary};
      border: none;
    }
    .checkbox-outline.disabled {
      background: ${Color.Grey};
    }      
  }


  .service-selection-box {
    width: 100%;
    padding: 15px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    border: 1px solid ${palette('border', 0)};
  
    .isoImgWrapper {
      width: 35px;
      height: 35px;
      ${borderRadius("50%")};

      img {
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    
    .recycling-checkbox {
      text-align: right;
      padding-right: 4%;
      padding-top: 20px;

      .checkbox-outline {        
        width: 18px;
        height: 18px;
        ${borderRadius("50%")};
        border: 1px solid ${Color.Nobel};
        text-align: center;
      }
      .checkbox-outline.checked {
        background: ${Color.Primary};
        border: none;
      }
      .checkbox-outline.disabled {
        background: ${Color.Grey};
      }      
    }

  }
  
  .selected-category-container {
      
    .info-container {
      width: 100%;
      padding: 15px;
      border-radius: 4px;
      background: white;
      border: 1px solid ${palette('border', 0)};
      // box-shadow: 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);

      .left-col {

        .isoLogoWrapper {
          width: 45px;
          height: 45px;  
          padding: 5px;
          background: ${Color.Mercury};
          border-radius: 5px;
    
          img {
            height: 100%;
            object-fit: cover;
          }
        }

        .isoLogoWrapper.small {
          width: 25px;
          height: 25px;
          padding: 2px 5px;
        }

        .isoLogoWrapper.round-background {
          ${borderRadius("50%")};
        }
      }

      .align-middle { 
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .right-col.border-left {
        border-left: 2px solid ${Color.Grey};
      }
    }

    .next-icon {
      text-align: right;
      padding-right: 4%;
    }
  }
   
  

  .img-org {
    text-align: -webkit-center;
    margin-bottom: 20px;
    
    .isoImgWrapper {
      width: 100px;
      height: 100px;

      img {
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .submitButton {
    width: 100%;
  }


  .align-middle { 
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
 
  .isoImageCon {
    
    .isoIcon {
      border: 2px dashed ${Color.Primary};
      text-align: center;
      border-radius: 4px; 
      padding: 15px;      
    }


    .file-wrapper{
      display: flex;
      justify-content: space-between;
      padding: 15px;
      background: ${Color.Sand};
      border: 1px solid ${Color.Mercury};

    }
    
    .img-wrapper{
      padding: 15px;
      background: ${Color.Sand};
      border: 1px solid ${Color.Mercury};
      width: 100%;
      text-align: center;

      img {
        height: 50%;
        object-fit: cover;
        width: 50%;
      }
    }
  }

`;

const MapStyle = styled.div`
  

  .map-container {
    margin-bottom: 15px;
    width: 100%;
    height: 100vh;
  }

  .fixedTopButton {
    position: fixed;
    top: 100px;
    width: 100%;
    padding: 0 5%;
    
    input {    
      height: 40px;
      padding-left: 40px !important;
    }
  }


  .input-container i {
    position: absolute;
    z-index: 1;
  }

  .input-container {
    width: 100%;

    .input-box {
      position: relative;
    }
    
    .input-box > [data-clear-input] {
      font-weight: bold;
      font-size: 1.4em;
      line-height: 1em;
      cursor: pointer;
      position: absolute;
      right: 11%;
      top: 8px;
    }

    .input-box > input::-ms-clear {
      display: none;
    }



    .cancel-top {
      padding-top: 8px;
      color: ${Color.Secondary};
    }
  }

  .icon {
    padding: 2px 10px;
    text-align: center;
  }

  .icon.red {
    color: red;
  }
  
  .icon.font25 {
    font-size: 25px;
  }
  
  .icon.font18 {
    font-size: 18px;
  }


  .input-field {
    width: 100%;
    padding: 10px;
  }

  .location_container {
    position: fixed;
    top: 150px;
    width: 100%;
    padding: 0 5%;

    .current_container {
      border-bottom: 1px solid ${palette('border', 0)};
    }

    .location-btn {
      border-radius: 4px;
      background: ${palette("grayscale", 12)};
      padding: 5px 10px;
    }
  }

  .address-list-container {
    border-bottom: 1px solid ${palette('border', 0)};
    padding-top: 5px;

    .address-list-item {
        width: 100%;
    }

    .address-list-item .address-main-text {
        font-size: 17px;
        font-weight: bold;
        padding-top: 10px;
    }

    .address-list-item .address-secondary-text {
        font-size: 14px;
        color: rgb(180, 180, 180);
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .address-list-item .mb-y {
      margin-bottom: 0.45rem !important;
      margin-top: 0.55rem !important;
    }
    

  }

`;


const CustomStyles = styled.div`
  
    
  .pg-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000; 
    padding-top: 20px;
    padding-bottom: 15px;
    font-size: 15px;
    border-bottom: 1px solid ${palette('border', 0)};
    background: #ffffff;
  }

  .backBtn {
    position: fixed;
    top: 20px;
    left: 25px;
    font-size: 20px;

    span {
      font-size: 14px;
    }
  }
  


  .font-18 {
    font-size: 18px;
  }

  .font-22 {
    font-size: 22px;
  }

  .font-20 {
    font-size: 20px;
  }

  .font-25 {
    font-size: 25px;
  }

  .font-35 {
    font-size: 35px;
  }

  .font-16 {
    font-size: 16px;
  }

  .font-13 {
    font-size: 13px;
  }

  .font-11 {
    font-size: 11px;
  }

  .font-bold {
    font-weight: bold;
  }

  .color-grey {    
    color: ${Color.Nobel};
  }

  .color-darkgrey {    
    color: ${Color.DustyGrey};
  }

  .color-primary {    
    color: ${Color.Secondary};
  }


  /*===== Recycling Selection. =====*/  
  .selection-container {

    .recycling-container {
      
      width: 100%;
      background: ${palette("grayscale", 6)};
      padding: 10px;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      border: 1px solid ${palette('border', 0)};

      .align-middle { 
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .desc-container {
        display: flex;
        width: 80%;     

        .recycling-img {
    
          .isoImgWrapper {
            width: 60px;
            height: 60px;
            padding: 12px;
            background: ${Color.White};
            border-radius: 4px; 
    
            img {
              height: 100%;
              object-fit: cover;
            }
          }
        }
    
        .recycling-desc {
          vertical-align: middle;
        }
      }

      .desc-container.full-width {
        width: 100%;     
      }
      
      .recycling-checkbox {
        text-align: right;
        padding-right: 4%;

        .checkbox-outline {        
          width: 18px;
          height: 18px;
          ${borderRadius("50%")};
          border: 1px solid ${Color.Nobel};
          text-align: center;
        }
        .checkbox-outline.checked {
          background: ${Color.Primary};
          border: none;
        }
        .checkbox-outline.disabled {
          background: ${Color.Grey};
        }      
      }
    }

    .recycling-container.fix-height {
      min-height: 85px;
    }


    .recycling-container.selected {
      border: 1px solid ${Color.Secondary};
      background: ${Color.Tertiary};

      .isoImgWrapper {
        background: ${Color.White};
      }

    }
    
    .recycling-container.disabled {
      opacity:0.5;
    }
  }

  /*===== End Recycling Selection. =====*/


  /*===== Modal Wrap =====*/

  .ant-modal-wrap {
    top: unset;
  }

  .ant-modal {
    top: unset;
    bottom: 0;
  }

  .ant-modal-content {
    border-radius: 15px;
    width
  }
  /*===== End Modal Wrap =====*/

  
  /*===== Button Bottom =====*/

  .buttonBottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: ${Color.White};
    padding: 25px;
    text-align: center;
    z-index: 100;
    border-radius: 30px 30px 0 0;
    -webkit-box-shadow: 0px -4px 3px #fafafa;
    -moz-box-shadow: 0px -4px 3px #fafafa;
    box-shadow: 0px -4px 3px #fafafa;

    button {
      width: 100%;
    }

    .btn-1, .btn-2 {
      width: 50%;
    }
  }
  
  .width100 button{
    width: 100%;
  }

  .bottom-btn {
    border-radius: 10px;
    text-transform: capitalize !important;
    height: 55px !important;
    font-size: 16px !important;
    font-weight: bold !important;
  }

  .bottom-btn.grey-btn {  
    background: ${palette("grayscale", 12)};
    color: ${palette("grayscale", 13)};
  }

  .bottom-btn.dark-btn {
    background: ${Color.Salem};
    color: ${Color.White};
  }

  .bottom-btn.primary-btn {
    background: ${Color.Primary};
    color: ${Color.White};
  }

  .bottom-btn.green-btn {
    background: ${palette("grayscale", 13)};
    color: ${Color.White};
  }

  .bottom-btn.orange-btn {
    background: ${palette("grayscale", 14)};
    color: ${Color.White};
  }

  .bottom-btn.ant-btn-sm {
    height: 40px !important;
    font-size: 14px !important;
    padding: 0 25px;
  }

  .view-doc {
    padding: 0 35px !important;
    height: 45px !important;
  }

  .addBtnBackgroud {
    background: ${Color.Primary};    
    ${borderRadius("50%")};
    padding: 0 5px;
    text-align: center;
  }
  /*===== End Button Bottom =====*/

  

  .button-selection button {

    width: 100%;
  }

  .button-selection button.selection {

    text-align: center;
    text-transform: capitalize !important;
    font-size: 16px !important;
    font-weight: bold !important;
    height: 55px !important;

    i {
      
      font-size: 18px;
    }
  }
`;

const InvoiceModelStyle = styled.div`
  

  .document-container {
    margin: -24px;
    min-height: 970px;
  }

  .loading-text {
    text-align: center; 
    font-size: 18px;
    padding-top: 30px;
    font-weight: bold;
  }

  .document-button {
    color:grey;
    padding:10px;
  }

  .document-button > button{
    margin-right: 10px;
  }

  .document-button-load {
    padding:10px;

    i {
      color:white;
    }
    
    button{
      margin-right: 10px;
      border: none;
    }
  }
`;

export { InvoiceModelStyle, MapStyle, CustomStyles };

export default WithDirection(SignUpStyleWrapper);
