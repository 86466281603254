import React, { Component } from "react";
import "react-dates/initialize";

// Component

import { Document, Page } from 'react-pdf';
import 'material-design-icons/iconfont/material-icons.css'
import Button from "../../../components/uielements/button";
import Modal from "../../../components/feedback/modal";

import { InvoiceModelStyle } from "./style";

import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;




class ModalPDFView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      numPages: null,
      pageNumber: 1,
      pageLoaded: false,
      
    }

  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  }

  onPageRenderSuccess = () => {

    this.setState({ pageLoaded: true });
  }
  
  createPage = (pages) => {
    let page = []

    for (let i = 1; i <= pages; i++) {
      if(i > 1)
        page.push(<hr className={this.state.pageLoaded ? '' : 'hidden'} key={`xx_${i}`} />)

      page.push(<Page key={i} scale={1.5} pageNumber={i} width={500} 
        onRenderSuccess={this.onPageRenderSuccess} loading="" wrap/>)
    }
    return page
  }

  _onSave = async () => {

    this.props.onSave();
      
  };

  _onPrint = async () => {

    this.props.onPrint();
      
  };

  render() {
    
    const { numPages } = this.state;
    const { visible, data} = this.props;

     
   console.log({ visible, data, numPages})
    return (
        <Modal
          width={750}
          visible={visible}
          onCancel={this.props.onDismiss}
          footer={null}
        >
          <InvoiceModelStyle>
            <div className="document-container">

              <div className={this.state.pageLoaded ? 'document-button' : 'document-button-load'}>
                {/* <Button onClick={this._onSave}>
                  <i className='material-icons'>cloud_download</i>
                </Button> */}
                <Button onClick={this._onPrint}>
                  <i className='material-icons'>local_printshop</i>
                </Button>
              </div>

              <Document
                file={data.url}
                onLoadSuccess={this.onDocumentLoadSuccess}
                loading={<div className="loading-text">Loading...</div>}
                noData={<div className="loading-text">Loading...</div>}
              >
                {this.createPage(numPages)}
                
              </Document>
            </div>
          </InvoiceModelStyle>
        </Modal>
    );
  }
}

export default ModalPDFView;
