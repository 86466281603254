import { all, takeEvery, put, fork } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import * as actions from "../actions";
import * as API from "../api";
import { push } from "react-router-redux";
import Helper from "../../helpers/helpers";

const delay = (ms) => new Promise(res => setTimeout(res, ms))

export function* getSettlementStatus() {
  yield takeEvery(actionTypes.GET_SETTLEMENT_STATUS, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("GETSETTLEMENTSTATUS");    
    action.data.track_no = track_no
    
    try {
      const response = yield API.getSettlementStatus(action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {

          yield put(actions.getSettlementStatusSuccess(response.data.data));
          
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.getSettlementStatusFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.getSettlementStatusFail());
    }
  });
}


export function* placeWalkInOrder() {
  yield takeEvery(actionTypes.PLACE_WALKIN_ORDER, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("PLACEWALKINORDER");
    
    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    // yield put(actions.enableLoading());
    
    try {
      const response = yield API.placeWalkInOrder(action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {

          if(response.data.data.settlement_closed)
          {
            yield put(actions.getSettlementStatusSuccess(response.data.data));
            yield put(actions.placeWalkInOrderFail());

          }else
            yield put(actions.placeWalkInOrderSuccess(response.data.data));
          
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.placeWalkInOrderFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.placeWalkInOrderFail());
    }
  });
}

export function* scanUserQRcode() {
  yield takeEvery(actionTypes.SCAN_USER_QR, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("SCANUSERQR");
    
    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    // yield put(actions.enableLoading());
    
    try {
      const response = yield API.scanUserQRcode(action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {

          if(response.data.data.settlement_closed)
          {
            yield put(actions.getSettlementStatusSuccess(response.data.data));
            yield put(actions.scanUserQRcodeFail());

          }else
            yield put(actions.scanUserQRcodeSuccess(response.data.data));
          
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.scanUserQRcodeFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.scanUserQRcodeFail());
    }
  });
}


export function* startGrading() {
  yield takeEvery(actionTypes.START_GRADING, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("STARTGRADING");
    
    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    // yield put(actions.enableLoading());
    
    try {
      const response = yield API.startGrading(action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {

          if(response.data.data.settlement_closed)
          {
            yield put(actions.getSettlementStatusSuccess(response.data.data));
            yield put(actions.startGradingFail());

          }else
            yield put(actions.startGradingSuccess(response.data.data));
          
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.startGradingFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.startGradingFail());
    }
  });
}


export function* getPendingWalkInOrder() {
  yield takeEvery(actionTypes.GET_PENDING_WALKIN_ORDER, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("GETPENDINGWALKINORDER");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());
    
    try {
      const response = yield API.getPendingWalkInOrder(action.data);
      if(response)
      {
        if (response.data.success) {

          yield put(actions.getPendingWalkInOrderSuccess(response.data.data));
          
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.getPendingWalkInOrderFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.getPendingWalkInOrderFail());
    }
  });
}


export function* cancelWalkInOrder() {
  yield takeEvery(actionTypes.CANCEL_WALKIN_ORDER, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("CANCELWALKINORDER");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());
    
    try {
      const response = yield API.cancelWalkInOrder(action.id, action.data);
      if(response)
      {
        if (response.data.success) {

          yield put(actions.cancelWalkInOrderSuccess());
          yield put(push("/walk-in-grading/cancel"));
          
          
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.cancelWalkInOrderFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.cancelWalkInOrderFail());
    }
  });
}


export function* completeWalkInOrder() {
  yield takeEvery(actionTypes.COMPLETE_WALKIN_ORDER, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("COMPLETEWALKINORDER");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());
    
    try {
      const response = yield API.completeWalkInOrder(action.id, action.data);
      if(response)
      {
        if (response.data.success) {

          if(response.data.data && response.data.data.settlement_closed)
          {
            yield put(actions.getSettlementStatusSuccess(response.data.data));
            yield put(actions.completeWalkInOrderFail());
            
          }else{
            yield delay(500);
            yield put(actions.completeWalkInOrderSuccess());
          }
          
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.completeWalkInOrderFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.completeWalkInOrderFail());
    }
  });
}


export function* gradeAgainCompletedWalkInOrder() {
  yield takeEvery(actionTypes.GRADE_AGAIN_COMPLETED_WALKIN_ORDER, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("GRADEAGAINWALKINORDER");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());
    
    try {
      const response = yield API.gradeAgainCompletedWalkInOrder(action.id, action.data);
      if(response)
      {
        if (response.data.success) {

          if(response.data.data && response.data.data.settlement_closed)
          {
            yield put(actions.getSettlementStatusSuccess(response.data.data));
            yield put(actions.gradeAgainCompletedWalkInOrderFail());
            
          }else{
            yield delay(500);
            yield put(actions.gradeAgainCompletedWalkInOrderSuccess(response.data.data));
          }
          
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.gradeAgainCompletedWalkInOrderFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.gradeAgainCompletedWalkInOrderFail());
    }
  });
}


export function* cancelCompletedWalkInOrder() {
  yield takeEvery(actionTypes.CANCEL_COMPLETED_WALKIN_ORDER, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("CANCELCOMPLETEDWALKINORDER");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());
    
    try {
      const response = yield API.cancelCompletedWalkInOrder(action.id, action.data);
      if(response)
      {
        if (response.data.success) {

          if(response.data.data && response.data.data.settlement_closed)
          {
            yield put(actions.getSettlementStatusSuccess(response.data.data));
            yield put(actions.cancelCompletedWalkInOrderFail());
            
          }else{
            yield delay(500);
            yield put(actions.cancelCompletedWalkInOrderSuccess(response.data.data));
          }
          
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.cancelCompletedWalkInOrderFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.cancelCompletedWalkInOrderFail());
    }
  });
}

export function* walkInOrderDetail() {
  yield takeEvery(actionTypes.WALKIN_ORDER_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("WALKINORDERDETAIL");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());
    
    try {
      const response = yield API.walkInOrderDetail(action.id, action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {

          yield put(actions.walkInOrderDetailSuccess(response.data.data));
          
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.walkInOrderDetailFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.walkInOrderDetailFail());
    }
  });
}


export function* addWalkInOrderRecycling() {
  yield takeEvery(actionTypes.WALKIN_ORDER_RECYCLING_ADD, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("WALKINORDERRECYCLINGADD");
    action.data.track_no = track_no

    yield put(actions.enableLoading());
    
    try {
      const response = yield API.addWalkInOrderRecycling(action.id, action.data);
      if(response)
      {
        if (response.data.success) {

          if(response.data.data && response.data.data.price_update)
          {
            yield put(actions.disableLoading());
            yield put(actions.walkInOrderRecyclingPriceUpdConfirm(response.data.data));

          }else{
            yield delay(500);
            yield put(actions.disableLoading());
            yield put(actions.addWalkInOrderRecyclingSuccess());
            yield put(actions.msgSuccess(response.data.message));
          }
          
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.addWalkInOrderRecyclingFail());
        }
      }
      
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.addWalkInOrderRecyclingFail());
    }
  });
}



export function* updateWalkInOrderRecycling() {
  yield takeEvery(actionTypes.WALKIN_ORDER_RECYCLING_UPDATE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("WALKINORDERRECYCLINGUPDATE");
    action.data.track_no = track_no

    yield put(actions.enableLoading());
    
    try {
      const response = yield API.updateWalkInOrderRecycling(action.id, action.recycling, action.data);
      if(response)
      {
        if (response.data.success) {

          if(response.data.data && response.data.data.price_update)
          {
            yield put(actions.disableLoading());
            yield put(actions.walkInOrderRecyclingPriceUpdConfirm(response.data.data));

          }else{
            
            yield delay(500);
            yield put(actions.disableLoading());
            yield put(actions.updateWalkInOrderRecyclingSuccess());
            yield put(actions.msgSuccess(response.data.message));
          }
          
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.updateWalkInOrderRecyclingFail());
        }
      }
      
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.updateWalkInOrderRecyclingFail());
    }
  });
}



export function* removeWalkInOrderRecycling() {
  yield takeEvery(actionTypes.WALKIN_ORDER_RECYCLING_REMOVE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("WALKINORDERRECYCLINGREMOVE");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());
    
    try {
      const response = yield API.removeWalkInOrderRecycling(action.id, action.data);
      if(response)
      {
        if (response.data.success) {

          yield delay(500);
          yield put(actions.removeWalkInOrderRecyclingSuccess());
          yield put(actions.msgSuccess(response.data.message));
          
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.removeWalkInOrderRecyclingFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.removeWalkInOrderRecyclingFail());
    }
  });
}



export function* walkInOrderSetZone() {
  yield takeEvery(actionTypes.WALKIN_ORDER_SET_ZONE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("WALKINORDERSETZONE");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());
    
    try {
      const response = yield API.walkInOrderSetZone(action.id, action.data);
      if(response)
      {
        if (response.data.success) {

          yield delay(500);
          yield put(actions.walkInOrderSetZoneSuccess());
          
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.walkInOrderSetZoneFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.walkInOrderSetZoneFail());
    }
  });
}


export function* walkInOrderCategorySetZone() {
  yield takeEvery(actionTypes.WALKIN_ORDER_CATEGORY_SET_ZONE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("WALKINORDERCATEGORYSETZONE");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());
    
    try {
      const response = yield API.walkInOrderCategorySetZone(action.id, action.data);
      if(response)
      {
        if (response.data.success) {

          yield delay(500);
          yield put(actions.walkInOrderCategorySetZoneSuccess());
          
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.walkInOrderCategorySetZoneFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.walkInOrderCategorySetZoneFail());
    }
  });
}


export function* loadOrderHistoryCount() {
  
  yield takeEvery(actionTypes.ORDER_HISTORY_COUNT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ORDERHISTORYCOUNT");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.loadOrderHistoryCount(action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
                  
          yield put(actions.loadOrderHistoryCountSuccess(response.data.data));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.loadOrderHistoryCountFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.loadOrderHistoryCountFail());
    }
  });
}

export function* loadOrderHistoryList() {
  
  yield takeEvery(actionTypes.ORDER_HISTORY_LIST, function*(action) {

    if(!action.data) action.data = {}

    let service = (action.data.service) ? action.data.service : ""
    let order_status = (action.data.order_status) ? action.data.order_status : ""
    
    let track_no = Helper.generateTrackNo(`ORDERHISTORYLIST${service}${order_status}`);
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.loadOrderHistoryList(action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
          
          let params = {
            data: response.data.data,
            offset: action.data.offset,
            limit: action.data.limit,
            total_result: response.data.data.length,
            total: response.data.total,
          }
          
          yield put(actions.loadOrderHistoryListSuccess(params));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.loadOrderHistoryListFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.loadOrderHistoryListFail());
    }
  });
}


export function* loadOrderHistoryDetail() {
  
  yield takeEvery(actionTypes.ORDER_HISTORY_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ORDERHISTORYDETAIL");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.loadOrderHistoryDetail(action.id, action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
      
          yield put(actions.loadOrderHistoryDetailSuccess(response.data.data));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.loadOrderHistoryDetailFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.loadOrderHistoryDetailFail());
    }
  });
}




export function* loadGradingTaskList() {
  
  yield takeEvery(actionTypes.GRADING_TASK_LIST, function*(action) {

    if(!action.data) action.data = {}

    let status = (action.data.status) ? action.data.status : ""

    let track_no = Helper.generateTrackNo(`GRADINGTASKLIST${status}`);

    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.loadGradingTaskList(action.data);
      
      if(response)
      {
        if (response.data.success && response.data.data) {
          
          let params = {
            data: response.data.data,
            offset: action.data.offset,
            limit: action.data.limit,
            total_result: response.data.data.length,
            total: response.data.total,
          }
          
          yield put(actions.loadGradingTaskListSuccess(params));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.loadGradingTaskListFail());
        }
      }

      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.loadGradingTaskListFail());
    }
  });
}


export function* loadGradingTaskDetail() {
  
  yield takeEvery(actionTypes.GRADING_TASK_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("GRADINGTASKDETAIL");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.loadGradingTaskDetail(action.id, action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
      
          yield put(actions.loadGradingTaskDetailSuccess(response.data.data));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.loadGradingTaskDetailFail());
        }
      }

      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.loadGradingTaskDetailFail());
    }
  });
}


export function* acceptGradingTask() {
  
  yield takeEvery(actionTypes.GRADING_TASK_ACCEPT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("GRADINGTASKACCEPT");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.acceptGradingTask(action.id, action.data);
      if(response)
      {
        if (response.data.success) {
      
          yield put(actions.acceptGradingTaskSuccess());
          yield put(actions.msgSuccess(response.data.message));

        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.acceptGradingTaskFail());
        }
      }

      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.acceptGradingTaskFail());
    }
  });
}

export function* rejectGradingTask() {
  
  yield takeEvery(actionTypes.GRADING_TASK_REJECT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("GRADINGTASKREJECT");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.rejectGradingTask(action.id, action.data);
      if(response)
      {
        if (response.data.success) {
     
          yield put(actions.rejectGradingTaskSuccess());
          yield put(actions.msgSuccess(response.data.message));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.rejectGradingTaskFail());
        }
      }

      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.rejectGradingTaskFail());
    }
  });
}

export function* gradingTaskSetZone() {
  
  yield takeEvery(actionTypes.GRADING_TASK_SET_ZONE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("GRADINGTASKSETZONE");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.gradingTaskSetZone(action.id, action.data);
      if(response)
      {
        if (response.data.success) {
      
          yield delay(1000);
          yield put(actions.gradingTaskSetZoneSuccess());
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.gradingTaskSetZoneFail());
        }
      }

      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.gradingTaskSetZoneFail());
    }
  });
}

export function* gradingTaskCategorySetZone() {
  
  yield takeEvery(actionTypes.GRADING_TASK_CATEGORY_SET_ZONE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("GRADINGTASKCATEGORYSETZONE");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.gradingTaskCategorySetZone(action.id, action.data);
      if(response)
      {
        if (response.data.success) {
      
          yield delay(1000);
          yield put(actions.gradingTaskCategorySetZoneSuccess());
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.gradingTaskCategorySetZoneFail());
        }
      }

      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.gradingTaskCategorySetZoneFail());
    }
  });
}


export function* addGradingTaskRecycling() {
  
  yield takeEvery(actionTypes.GRADING_TASK_RECYCLING_ADD, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("GRADINGTASKRECYCLINGADD");
    
    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.addGradingTaskRecycling(action.id, action.data);
      if(response)
      {
        if (response.data.success) {

          if(response.data.data && response.data.data.price_update)
          {
            yield put(actions.gradingTaskRecyclingPriceUpdConfirm(response.data.data));

          }else{

            yield delay(1000);
            yield put(actions.addGradingTaskRecyclingSuccess());
            yield put(actions.msgSuccess(response.data.message));
          }

        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.addGradingTaskRecyclingFail());
        }
      }

      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.addGradingTaskRecyclingFail());
    }
  });
}


export function* updateGradingTaskRecycling() {
  yield takeEvery(actionTypes.GRADING_TASK_RECYCLING_UPDATE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("GRADINGTASKRECYCLINGUPDATE");
    
    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    // yield put(actions.enableLoading());
    
    try {
      const response = yield API.updateGradingTaskRecycling(action.id, action.recycling, action.data);
      if(response)
      {
        if (response.data.success) {

          if(response.data.data && response.data.data.price_update)
          {
            yield put(actions.walkInOrderRecyclingPriceUpdConfirm(response.data.data));

          }else{
            
            yield delay(1000);
            yield put(actions.updateGradingTaskRecyclingSuccess());
            yield put(actions.msgSuccess(response.data.message));
          }
          
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.updateGradingTaskRecyclingFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.updateGradingTaskRecyclingFail());
    }
  });
}


export function* removeGradingTaskRecycling() {
  yield takeEvery(actionTypes.GRADING_TASK_RECYCLING_REMOVE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("GRADINGTASKRECYCLINGREMOVE");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());
    
    try {
      const response = yield API.removeGradingTaskRecycling(action.id, action.data);
      if(response)
      {
        if (response.data.success) {

          yield delay(1000);
          yield put(actions.removeGradingTaskRecyclingSuccess());
          yield put(actions.msgSuccess(response.data.message));
          
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.removeGradingTaskRecyclingFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.removeGradingTaskRecyclingFail());
    }
  });
}



export function* uploadGradingTaskDocument() {
  yield takeEvery(actionTypes.GRADING_TASK_UPLOAD_DOC, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("GRADINGTASKUPLOADDOC");

    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    // yield put(actions.enableLoading());
    
    try {
      const response = yield API.uploadGradingTaskDocument(action.id, action.data);
      if(response)
      {
        if (response.data.success) {

          yield delay(1000);
          yield put(actions.uploadGradingTaskDocumentSuccess());
          yield put(actions.msgSuccess(response.data.message));
          
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.uploadGradingTaskDocumentFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.uploadGradingTaskDocumentFail());
    }
  });
}



export function* completeGradingTask() {
  yield takeEvery(actionTypes.COMPLETE_GRADING_TASK, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("COMPLETEGRADINGTASK");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());
    
    try {
      const response = yield API.completeGradingTask(action.id, action.data);
      if(response)
      {
        if (response.data.success) {

          if(response.data.data && response.data.data.settlement_closed)
          {
            yield put(actions.getSettlementStatusSuccess(response.data.data));
            yield put(actions.completeGradingTaskFail());
            
          }else{
            yield delay(1000);
            yield put(actions.completeGradingTaskSuccess());
          }
          
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.completeGradingTaskFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.completeGradingTaskFail());
    }
  });
}



export function* loadWasteGradingTaskList() {
  
  yield takeEvery(actionTypes.WASTE_GRADING_TASK_LIST, function*(action) {

    if(!action.data) action.data = {}

    let status = (action.data.status) ? action.data.status : ""

    let track_no = Helper.generateTrackNo(`WASTEGRADINGTASKLIST${status}`);

    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.loadWasteGradingTaskList(action.data);
      
      if(response)
      {
        if (response.data.success && response.data.data) {
          
          let params = {
            data: response.data.data,
            offset: action.data.offset,
            limit: action.data.limit,
            total_result: response.data.data.length,
            total: response.data.total,
          }
          
          yield put(actions.loadWasteGradingTaskListSuccess(params));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.loadWasteGradingTaskListFail());
        }
      }

      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.loadWasteGradingTaskListFail());
    }
  });
}


export function* loadWasteGradingTaskDetail() {
  
  yield takeEvery(actionTypes.WASTE_GRADING_TASK_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("WASTEGRADINGTASKDETAIL");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.loadWasteGradingTaskDetail(action.id, action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
      
          yield put(actions.loadWasteGradingTaskDetailSuccess(response.data.data));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.loadWasteGradingTaskDetailFail());
        }
      }

      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.loadWasteGradingTaskDetailFail());
    }
  });
}


export function* uploadWasteGradingTaskDocument() {
  yield takeEvery(actionTypes.WASTE_GRADING_TASK_UPLOAD_DOC, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("WASTEGRADINGTASKUPLOADDOC");

    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    // yield put(actions.enableLoading());
    
    try {
      const response = yield API.uploadWasteGradingTaskDocument(action.id, action.data);
      if(response)
      {
        if (response.data.success) {

          yield delay(1000);
          yield put(actions.uploadWasteGradingTaskDocumentSuccess());
          yield put(actions.msgSuccess(response.data.message));
          
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.uploadWasteGradingTaskDocumentFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.uploadWasteGradingTaskDocumentFail());
    }
  });
}



export function* completeWasteGradingTask() {
  yield takeEvery(actionTypes.COMPLETE_WASTE_GRADING_TASK, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("COMPLETEWASTEGRADINGTASK");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());
    
    try {
      const response = yield API.completeWasteGradingTask(action.id, action.data);
      if(response)
      {
        if (response.data.success) {

          if(response.data.data && response.data.data.settlement_closed)
          {
            yield put(actions.getSettlementStatusSuccess(response.data.data));
            yield put(actions.completeWasteGradingTaskFail());
            
          }else{
            yield delay(1000);
            yield put(actions.completeWasteGradingTaskSuccess());
          }
          
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.completeWasteGradingTaskFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.completeWasteGradingTaskFail());
    }
  });
}



export function* registerUser() {
  yield takeEvery(actionTypes.REGISTER_USER, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("REGISTERUSER");
    
    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    // yield put(actions.enableLoading());
    
    try {
      const response = yield API.registerUser(action.data);
      if(response)
      {
        if (response.data.success) {

          yield delay(1000);
          yield put(actions.registerUserSuccess(response.data.data));
                    
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.registerUserFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.registerUserFail());
    }
  });
}


export function* checkUserExist() {
  yield takeEvery(actionTypes.CHECK_USER_EXIST, function*(action) {

    if(!action.data) action.data = {}

    let filter_phone = (action.data.user_contact) ? action.data.user_contact : ""

    let track_no = Helper.generateTrackNo(`${filter_phone}CHECKUSEREXIST`);
    
    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    // yield put(actions.enableLoading());
    
    try {
      const response = yield API.checkUserExist(action.data);
      if(response)
      {
        if (response.data.success) {

          yield delay(1000);
          yield put(actions.checkUserExistSuccess(response.data.data));
                    
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.checkUserExistFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.checkUserExistFail());
    }
  });
}


export function* resetUserExist() {
  
  yield takeEvery(actionTypes.RESET_USER_EXIST, function*(action) {
    
    yield put(actions.resetUserExistSuccess());
  });

}

export default function* orderSaga() {
  
  yield all([fork(getSettlementStatus)]);
  yield all([fork(placeWalkInOrder)]);
  yield all([fork(scanUserQRcode)]);
  yield all([fork(startGrading)]);
  yield all([fork(getPendingWalkInOrder)]);
  yield all([fork(cancelWalkInOrder)]);
  yield all([fork(completeWalkInOrder)]);  
  yield all([fork(gradeAgainCompletedWalkInOrder)]);
  yield all([fork(walkInOrderDetail)]);
  yield all([fork(addWalkInOrderRecycling)]);
  yield all([fork(updateWalkInOrderRecycling)]);
  yield all([fork(removeWalkInOrderRecycling)]);
  yield all([fork(walkInOrderSetZone)]);
  yield all([fork(walkInOrderCategorySetZone)]);
  yield all([fork(loadOrderHistoryCount)]);
  yield all([fork(loadOrderHistoryList)]);
  yield all([fork(loadOrderHistoryDetail)]);
  yield all([fork(cancelCompletedWalkInOrder)]);
  

  yield all([fork(loadGradingTaskList)]);
  yield all([fork(loadGradingTaskDetail)]);
  yield all([fork(acceptGradingTask)]);
  yield all([fork(rejectGradingTask)]);
  yield all([fork(gradingTaskSetZone)]);
  yield all([fork(gradingTaskCategorySetZone)]);
  yield all([fork(addGradingTaskRecycling)]);
  yield all([fork(updateGradingTaskRecycling)]);
  yield all([fork(removeGradingTaskRecycling)]);
  yield all([fork(uploadGradingTaskDocument)]);
  yield all([fork(completeGradingTask)]);
  yield all([fork(registerUser)]);
  yield all([fork(checkUserExist)]);
  yield all([fork(resetUserExist)]);
  
  yield all([fork(loadWasteGradingTaskList)]);
  yield all([fork(loadWasteGradingTaskDetail)]);
  yield all([fork(uploadWasteGradingTaskDocument)]);
  yield all([fork(completeWasteGradingTask)]);
}
