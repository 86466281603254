import * as actionTypes from "./actionTypes";



export const createGrader = (data) => {
  return {
    type: actionTypes.CREATE_GRADER,
    data
  };
};

export const createGraderSuccess = () => {
  return {
    type: actionTypes.CREATE_GRADER_SUCCESS
  };
};

export const createGraderFail = () => {
  return {
    type: actionTypes.CREATE_GRADER_FAIL
  };
};



export const updateGrader = (id,data) => {
  return {
    type: actionTypes.UPDATE_GRADER,
    id,
    data
  };
};

export const updateGraderSuccess = () => {
  return {
    type: actionTypes.UPDATE_GRADER_SUCCESS
  };
};

export const updateGraderFail = () => {
  return {
    type: actionTypes.UPDATE_GRADER_FAIL
  };
};


export const uploadDocumentation = (data) => {
  return {
    type: actionTypes.UPLOAD_DOCUMENTATION,
    data
  };
};

export const uploadDocumentationSuccess = () => {
  return {
    type: actionTypes.UPLOAD_DOCUMENTATION_SUCCESS
  };
};

export const uploadDocumentationFail = () => {
  return {
    type: actionTypes.UPLOAD_DOCUMENTATION_FAIL
  };
};


export const loadGraderDocumentation = (data) => {
  return {
    type: actionTypes.LOAD_GRADER_DOCUMENTATION,
    data
  };
};

export const loadGraderDocumentationSuccess = (data) => {
  return {
    type: actionTypes.LOAD_GRADER_DOCUMENTATION_SUCCESS,
    data
  };
};

export const loadGraderDocumentationFail = () => {
  return {
    type: actionTypes.LOAD_GRADER_DOCUMENTATION_FAIL
  };
};



export const loadGraderList = data => {
  
  return {
    type: actionTypes.LOAD_GRADER_LIST,
    data
  };
};

export const loadGraderListSuccess = data => {
  return {
    type: actionTypes.LOAD_GRADER_LIST_SUCCESS,
    data
  };
};

export const loadGraderListFail = () => {
  return {
    type: actionTypes.LOAD_GRADER_LIST_FAIL
  };
};


export const loadGraderDetail = (id, data) => {
  
  return {
    type: actionTypes.LOAD_GRADER_DETAIL,
    id,
    data
  };
};

export const loadGraderDetailSuccess = data => {
  return {
    type: actionTypes.LOAD_GRADER_DETAIL_SUCCESS,
    data
  };
};

export const loadGraderDetailFail = () => {
  return {
    type: actionTypes.LOAD_GRADER_DETAIL_FAIL
  };
};



export const delGrader = (id,data) => {
  
  return {
    type: actionTypes.REMOVE_GRADER,
    id,
    data
  };
};

export const delGraderSuccess = () => {
  
  return {
    type: actionTypes.REMOVE_GRADER_SUCCESS,
  };
};

export const delGraderFail = () => {
  return {
    type: actionTypes.REMOVE_GRADER_FAIL
  };
};




export const loadAnnouncement = (data) => {
  return {
    type: actionTypes.LOAD_ANNOUNCEMENT,
    data
  };
};

export const loadAnnouncementSuccess = data => {
  return {
    type: actionTypes.LOAD_ANNOUNCEMENT_SUCCESS,
    data
  };
};

export const loadAnnouncementFail = () => {
  return {
    type: actionTypes.LOAD_ANNOUNCEMENT_FAIL
  };
};



export const switchLanguage = (data) => {
  return {
    type: actionTypes.SWITCH_LANGUAGE,
    data
  };
};

export const switchLanguageSuccess = () => {
  return {
    type: actionTypes.SWITCH_LANGUAGE_SUCCESS
  };
};

export const switchLanguageFail = () => {
  return {
    type: actionTypes.SWITCH_LANGUAGE_FAIL
  };
};

