import { all, takeEvery, put, fork } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import * as actions from "../actions";
import * as API from "../api";
import { push } from "react-router-redux";
import Helper from "../../helpers/helpers";

const delay = (ms) => new Promise(res => setTimeout(res, ms))



export function* loadZoneCount() {
  
  yield takeEvery(actionTypes.LOAD_ZONE_COUNT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ZONECOUNT");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.loadZoneCount(action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {

          yield put(actions.loadZoneCountSuccess(response.data.data));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.loadZoneCountFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.loadZoneCountFail());
    }
  });
}


export function* loadZoneList() {
  
  yield takeEvery(actionTypes.LOAD_ZONE_LIST, function*(action) {

    let val = {dump_recycling: "DR", dump_express: "DE"}

    if(!action.data) action.data = {}

    let service = (action.data.service_type && val[action.data.service_type]) ? val[action.data.service_type] : ""

    let track_no = Helper.generateTrackNo(`ZONELIST${service}`);
    
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.loadZoneList(action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
          
          let params = {
            data: response.data.data,
            offset: action.data.offset,
            limit: action.data.limit,
            total_result: response.data.data.length,
            total: response.data.total,
          }
          
          yield put(actions.loadZoneListSuccess(params));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.loadZoneListFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.loadZoneListFail());
    }
  });
}


export function* loadZoneDetail() {
  
  yield takeEvery(actionTypes.LOAD_ZONE_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ZONEDETAIL");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.loadZoneDetail(action.id, action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
      
          yield put(actions.loadZoneDetailSuccess(response.data.data));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.loadZoneDetailFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.loadZoneDetailFail());
    }
  });
}


export function* zoneCurrentStockDR() {
  
  yield takeEvery(actionTypes.LOAD_ZONE_CURRENT_STOCK_DR, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ZONECURRENTSTOCKDR");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.zoneCurrentStockDR(action.id, action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
      
          yield put(actions.zoneCurrentStockDRSuccess(response.data.data));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.zoneCurrentStockDRFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.zoneCurrentStockDRFail());
    }
  });
}


export function* createZone() {
  yield takeEvery(actionTypes.CREATE_ZONE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("CREATEZONE");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());
    
    try {
      const response = yield API.createZone(action.data);
      if(response)
      {
        if (response.data.success) {
          
          yield delay(1000);
          yield put(actions.createZoneSuccess());
          yield put(actions.msgSuccess(response.data.message));
          yield put(push("/zone"));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.createZoneFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.createZoneFail());
    }
  });
}

export function* updateZone() {
  yield takeEvery(actionTypes.UPDATE_ZONE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("UPDATEZONE");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.updateZone(action.id, action.data);
      if(response)
      {
        if (response.data.success) {
          
          yield delay(1000);
          yield put(actions.updateZoneSuccess());
          yield put(actions.msgSuccess(response.data.message));
          yield put(push("/zone"));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.updateZoneFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.updateZoneFail());
    }
  });
}

export function* delZone() {
  
  yield takeEvery(actionTypes.REMOVE_ZONE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("REMOVEZONE");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());
    try {
      const response = yield API.delZone(action.id, action.data);
      if(response)
      {
        if (response.data.success) {
          
          yield delay(1000);
          yield put(actions.delZoneSuccess());
          yield put(actions.msgSuccess(response.data.message));
          yield put(push("/zone"));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.delZoneFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.delZoneFail());
    }
  });
}


export function* updateZonePlcement() {
  yield takeEvery(actionTypes.UPDATE_ZONE_PLACEMENT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("UPDZONEPLACEMENT");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.updateZonePlcement(action.id, action.data);
      if(response)
      {
        if (response.data.success) {
          
          yield delay(1000);
          yield put(actions.updateZonePlcementSuccess());
          yield put(actions.msgSuccess(response.data.message));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.updateZonePlcementFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.updateZonePlcementFail());
    }
  });
}


export function* removeZonePlcement() {
  yield takeEvery(actionTypes.REMOVE_ZONE_PLACEMENT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("REMOVEZONEPLACEMENT");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.removeZonePlcement(action.id, action.data);
      if(response)
      {
        if (response.data.success) {
          
          yield delay(1000);
          yield put(actions.removeZonePlcementSuccess());
          yield put(actions.msgSuccess(response.data.message));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.removeZonePlcementFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.removeZonePlcementFail());
    }
  });
}



export function* updateZoneFullyFilled() {
  yield takeEvery(actionTypes.UPDATE_ZONE_FULLY_FILLED, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("UPDZONEFULLYFILLED");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.updateZoneFullyFilled(action.id, action.data);
      if(response)
      {
        if (response.data.success) {
          
          yield delay(1000);
          yield put(actions.updateZoneFullyFilledSuccess());
          yield put(actions.msgSuccess(response.data.message));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.updateZoneFullyFilledFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.updateZoneFullyFilledFail());
    }
  });
}


export function* loadExpressDumpsterRecyclingList() {
  yield takeEvery(actionTypes.LOAD_EXPRESS_DUMPSTER_RECYCLING_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("LOADEDUMPRECYCLINGLIST");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.loadExpressDumpsterRecyclingList(action.id, action.data);
      if(response)
      {
        if (response.data.success) {
          yield put(actions.loadExpressDumpsterRecyclingListSuccess(response.data));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.loadExpressDumpsterRecyclingListFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.loadExpressDumpsterRecyclingListFail());
    }
  });
}


export function* updateZoneCollection() {
  yield takeEvery(actionTypes.UPDATE_ZONE_COLLECTION, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("UPDZONECOLLECTION");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.updateZoneCollection(action.id, action.data);
      if(response)
      {
        if (response.data.success) {
          
          yield delay(1000);
          yield put(actions.updateZoneCollectionSuccess());
          yield put(actions.msgSuccess(response.data.message));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.updateZoneCollectionFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.updateZoneCollectionFail());
    }
  });
}

export function* loadDumpsterHistoryList() {
  yield takeEvery(actionTypes.LOAD_DUMPSTER_HISTORY_LIST, function*(action) {

    if(!action.data) action.data = {}

    let filter_date = (action.data.filter_date) ? action.data.filter_date : ""

    let track_no = Helper.generateTrackNo(`DUMPSTERHISTORYLIST${filter_date}`);
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.loadDumpsterHistoryList(action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
          
          let params = {
            data: response.data.data,
            offset: action.data.offset,
            limit: action.data.limit,
            total_result: response.data.data.length,
            total: response.data.total,
          }
          
          yield put(actions.loadDumpsterHistoryListSuccess(params));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.loadDumpsterHistoryListFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.loadDumpsterHistoryListFail());
    }
  });
}


export function* loadExpressPackingList() {
  yield takeEvery(actionTypes.LOAD_EXPRESS_PACKING_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EXPRESSPACKINGLIST");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.loadExpressPackingList(action.id, action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
          
          yield put(actions.loadExpressPackingListSuccess(response.data.data));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.loadExpressPackingListFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.loadExpressPackingListFail());
    }
  });
}



export function* generateRecyclingPackingList() {
  yield takeEvery(actionTypes.GENERATE_RECYCLING_PACKING_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("GENERATERECYCLINGPACKINGLIST");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.generateRecyclingPackingList(action.id, action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
          
          yield put(actions.generateRecyclingPackingListSuccess(response.data.data));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.generateRecyclingPackingListFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.generateRecyclingPackingListFail());
    }
  });
}

export function* loadRecyclingPackingHistoryList() {
  yield takeEvery(actionTypes.LOAD_RECYCLING_PACKING_HISTORY_LIST, function*(action) {

    if(!action.data) action.data = {}

    let filter_date = (action.data.filter_date) ? action.data.filter_date : ""

    let track_no = Helper.generateTrackNo(`RECYCLINGPACKINGHISTORYLIST${filter_date}`);
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.loadRecyclingPackingHistoryList(action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
          
          let params = {
            data: response.data.data,
            offset: action.data.offset,
            limit: action.data.limit,
            total_result: response.data.data.length,
            total: response.data.total,
          }
          
          yield put(actions.loadRecyclingPackingHistoryListSuccess(params));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.loadRecyclingPackingHistoryListFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.loadRecyclingPackingHistoryListFail());
    }
  });
}


export function* loadRecyclingPackingHistoryDetail() {
  yield takeEvery(actionTypes.LOAD_RECYCLING_PACKING_HISTORY_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("RECYCLINGPACKINGHISTORYDETAIL");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.loadRecyclingPackingHistoryDetail(action.id, action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
          
          yield put(actions.loadRecyclingPackingHistoryDetailSuccess(response.data.data));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.loadRecyclingPackingHistoryDetailFail());
        }
      }
      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.loadRecyclingPackingHistoryDetailFail());
    }
  });
}

export default function* zoneSaga() {
  
  yield all([fork(loadZoneList)]);
  yield all([fork(loadZoneCount)]);
  yield all([fork(loadZoneDetail)]);
  yield all([fork(zoneCurrentStockDR)]);
  yield all([fork(createZone)]);
  yield all([fork(updateZone)]);
  yield all([fork(delZone)]);  
  yield all([fork(updateZonePlcement)]);
  yield all([fork(removeZonePlcement)]);
  yield all([fork(updateZoneFullyFilled)]);
  yield all([fork(loadExpressDumpsterRecyclingList)]);
  yield all([fork(updateZoneCollection)]);
  yield all([fork(loadDumpsterHistoryList)]);
  yield all([fork(loadExpressPackingList)]);
  yield all([fork(generateRecyclingPackingList)]);
  yield all([fork(loadRecyclingPackingHistoryList)]);
  yield all([fork(loadRecyclingPackingHistoryDetail)]);
}
