import React, { Component } from "react";
import { cloneDeep } from "lodash";
import { connect } from "react-redux";

// Component
import Input from "../../../components/uielements/input";
import Form from "../../../components/uielements/form";
import SignInStyleWrapper from "./style";
import * as actions from "../../../redux/actions";
import { validateForm, validateField } from "../../../helpers/validation";
import Modals from "../../../components/feedback/modal";
import Icon from "../../../assets/components/Icon";
import Color from "../../../assets/Color";
import Button from "../../../components/uielements/button";

// UI Component

const FormItem = Form.Item;


class FirstLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        password: {
          value: "",
          validation: {
            rules: {
              required: true
            },
            error_message: {
              required: "Please Enter Access Password."
            }
          },
          valid: true
        },
      },
      error_message: {
        password: "",
      },
      screen: "",
    };

    // this.myRef = React.forwardRef();
    
  }

  componentDidUpdate(prevProps, nextProps) {
    
    const { field_errors, accPendingApproval } = this.props; 

    if (
      JSON.stringify(prevProps.field_errors) !== JSON.stringify(field_errors)
    ) {

      this._updateStateError(field_errors);
    }  

    if(
      JSON.stringify(prevProps.accPendingApproval) !==  JSON.stringify(accPendingApproval) &&
      accPendingApproval
    ){
      
      this._accPendingApproval(accPendingApproval);
    }
  }

  _accPendingApproval = (data) => {
          
    const handleOk = () => {

      this.props.onDismissFirstLogin();
    };
      
    Modals.error({
      title: data.title,
      content: data.msg,
      onOk() {
        handleOk();
      },
      okText: "OK",
    });

  }

  _proceedBack = (discard = false) => {
    
    if(discard) 
      this.props.onDismissFirstLogin();
    
    else
      this.setState({screen: "discard_change_confirm"})
      
  }
  
  _onContinue = () => {
    
    this.setState({screen: ""})
    
  };

  _onChangeText = (value) => {

    let fields = cloneDeep(this.state.fields);
    let error_message = cloneDeep(this.state.error_message);
    const { firstLoginErrorMsg }  = this.props
 
    fields["password"].value = value;
    
    this.setState({ fields }, () => {

      if(firstLoginErrorMsg)
        this.props.onClearFirstLoginErrMsg()
      
      let validator = validateField(fields, "password", error_message);

      this.setState({ ...validator });
    });

  };


  _onSubmit = async () => {

    let fields = cloneDeep(this.state.fields);
    let error_message = cloneDeep(this.state.error_message);
    let validator = await validateForm(fields, error_message);
    const { proceedFirstLoginParams } = this.props;

    this.setState({ ...validator.data }, () => {

      if (validator.valid) {

        let params = {
          contact_no: proceedFirstLoginParams.contact_no,
          veri_code: proceedFirstLoginParams.veri_code,
          password: fields["password"].value,
          oper: "login"
        };
   
        this.props.onFirstLogin(params);
        
      }
    });
  };


  render() {
    const { isFirstLoginFailed, firstLoginErrorMsg, isProcessFirstLogin }  = this.props
    const { fields, screen }  = this.state


    let err_msg = ""

    if(isFirstLoginFailed)
    {
      err_msg = firstLoginErrorMsg

      if(typeof firstLoginErrorMsg === "object" && firstLoginErrorMsg.length > 0)    
        err_msg = firstLoginErrorMsg[0].msg
    }

    return (
      <SignInStyleWrapper className="isoSignInPage">
          <div className="isoLoginContentWrapper">

              <div className="pg-header">
                <div 
                  className="backBtn"
                  onClick={() => this._proceedBack()}
                >
                  <Icon
                    type="custom"
                    name="back"
                    color={Color.Black}
                    size={15}
                  />
                </div>
              </div>


            <div className="isoLoginContent">

                <div className="color-primary font-bold font-30">Hi Grader,</div>
                
                <div className="mt-3 pb-3 font-17">For security reason, please enter your access password to continue.</div>

                <div className="isoSignInForm mt-5">
                  <Form layout="vertical">

                    <div className="isoInputWrapper">      
                    
                      <div className="font-11 color-darkgrey pb-1">Access Password</div>               
                      <FormItem
                        validateStatus={
                          !fields["password"].valid ? "error" : "validating"
                        }
                        help={this.state.error_message["password"]}
                      >
                        <div className="pt-1">
                          <Input
                            size="large"
                            type="text"
                            className="full-width"
                            autoComplete="off"
                            pattern="[0-9]+" 
                            value={fields["password"].value}
                            onChange={event => this._onChangeText(event.target.value) }
                            name="password"
                          />
                        </div>
                      </FormItem>
                    </div>

                    {isFirstLoginFailed && firstLoginErrorMsg && (

                      <div className="text-danger">{err_msg}</div>
                    )}
                                     

                  </Form>
                </div>
            </div>
          </div>

          <div className="buttonBottom">        
            <Button
              type="default"
              size="large"
              className="bottom-btn primary-btn"
              onClick={this._onSubmit}
              loading={isProcessFirstLogin}
            >
              Confirm
            </Button>
          </div>

          {screen !== "" && (
            <div className="ant-modal-root">
              <div className="ant-modal-mask"></div>
              <div className="ant-modal-wrap instagram-modal">
                <div className="ant-modal">
                  <div className="ant-modal-content">
                    <div className="ant-modal-body">

                      {screen === "discard_change_confirm" && (
                        <div>
                          <div className="pending-order-container mb-3">
                            <div className="text-bold font-18">Dismiss First Login? </div>
                          </div>

                          <div className="d-flex justify-content-between width100">                        

                            <Button
                              className="bottom-btn grey-btn mr-2"
                              size="large"
                              type="default"
                              onClick={() => this._proceedBack(true)}
                            >
                              Discard
                            </Button>

                            <Button
                              className="bottom-btn green-btn ml-2"
                              size="large"
                              type="default"
                              onClick={() => this._onContinue()}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      )}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </SignInStyleWrapper>
    );
  }
}

const mapStateToProps = state => {
  const { profileData, proceedFirstLoginParams, isProcessFirstLogin, isFirstLoginSuccess, firstLoginErrorMsg, isFirstLoginFailed, accPendingApproval } = state.Auth;

  return { profileData, proceedFirstLoginParams, isProcessFirstLogin, isFirstLoginSuccess, firstLoginErrorMsg, isFirstLoginFailed, accPendingApproval };

};

const mapDispatchToProps = dispatch => {
  return {
    
    onFirstLogin: params => dispatch(actions.firstLogin(params)),
    onClearFirstLoginErrMsg: () => dispatch(actions.clearFirstLoginErrMsg()),
    onDismissFirstLogin: () => dispatch(actions.dismissFirstLogin()),
    
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { pure: false }
)(FirstLogin);
