import { all, takeEvery, put, fork } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import * as actions from "../actions";
import * as API from "../api";
import Helper from "../../helpers/helpers";


export function* loadStateDistrictList() {

  yield takeEvery(actionTypes.LOAD_STATE_DISTRICT_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("STATEDISTRICTLIST");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.loadStateDistrictList( action.state, action.district, action.data );
      if(response)
      {
        if (response.data.success) {
          yield put(actions.loadStateDistrictListSuccess(response.data.data));
        } else {
          yield put(actions.msgError(response.data.message));
        }
      }

      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
    }
  });
}


export function* loadLanguages() {

  yield takeEvery(actionTypes.LOAD_LANGUAGUES, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("LANGUAGELIST");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.loadLanguages(action.data);
      if(response)
      {
        if (response.data.success) {
          yield put(actions.loadLanguagesSuccess(response.data.data));
        } else {
          yield put(actions.msgError(response.data.message));
        }
      }

      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
    }
  });
}


export function* loadExpressRecyclingList() {
  
  yield takeEvery(actionTypes.LOAD_EXPRESS_RECYCLING_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EXPRESSRECYCLINGLIST");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.loadExpressRecyclingList(action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
    
          yield put(actions.loadExpressRecyclingListSuccess(response.data.data));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.loadExpressRecyclingListFail());
        }
      }

      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.loadExpressRecyclingListFail());
    }
  });
}

export function* loadRecyclingList() {
  
  yield takeEvery(actionTypes.LOAD_RECYCLING_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("RECYCLINGLIST");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.loadRecyclingList(action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
    
          yield put(actions.loadRecyclingListSuccess(response.data.data));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.loadRecyclingListFail());
        }
      }

      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.loadRecyclingListFail());
    }
  });
}

export function* loadRecyclingCategory() {
  
  yield takeEvery(actionTypes.LOAD_RECYCLING_CATEGORY, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("RECYCLINGCATEGORY");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.loadRecyclingCategory(action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
    
          yield put(actions.loadRecyclingCategorySuccess(response.data.data));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.loadRecyclingCategoryFail());
        }
      }

      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.loadRecyclingCategoryFail());
    }
  });
}

export function* loadRecyclingExtraFee() {
  
  yield takeEvery(actionTypes.LOAD_RECYCLING_EXTRA_FEE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("RECYCLINGEXTRAFEE");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.loadRecyclingExtraFee(action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
    
          yield put(actions.loadRecyclingExtraFeeSuccess(response.data.data));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.loadRecyclingExtraFeeFail());
        }
      }

      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.loadRecyclingExtraFeeFail());
    }
  });
}

export function* loadRecyclingDumpster() {
  
  yield takeEvery(actionTypes.LOAD_RECYCLING_DMPSTER, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("RECYCLINGDMPSTER");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.loadRecyclingDumpster(action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
    
          yield put(actions.loadRecyclingDumpsterSuccess(response.data.data));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.loadRecyclingDumpsterFail());
        }
      }

      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.loadRecyclingDumpsterFail());
    }
  });
}

export function* loadDumpsterService() {
  
  yield takeEvery(actionTypes.LOAD_DMPSTER_SERVICE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DMPSTERSERVICE");
    action.data.track_no = track_no

    // yield put(actions.enableLoading());

    try {
      const response = yield API.loadDumpsterService(action.data);
      if(response)
      {
        if (response.data.success && response.data.data) {
    
          yield put(actions.loadDumpsterServiceSuccess(response.data.data));
        } else {
          yield put(actions.msgError(response.data.message));
          yield put(actions.loadDumpsterServiceFail());
        }
      }

      // yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      // yield put(actions.disableLoading());
      yield put(actions.loadDumpsterServiceFail());
    }
  });
}


export default function* generalSaga() {
  
  yield all([fork(loadStateDistrictList)]);
  yield all([fork(loadLanguages)]);
  yield all([fork(loadRecyclingList)]);
  yield all([fork(loadExpressRecyclingList)]);
  yield all([fork(loadRecyclingCategory)]);
  yield all([fork(loadRecyclingExtraFee)]);
  yield all([fork(loadRecyclingDumpster)]);
  yield all([fork(loadDumpsterService)]);
}
