import React from "react";
import { Provider } from "react-redux";
import { store, history, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import PublicRoutes from "./router";
import { ThemeProvider } from "styled-components";
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";
import themes from "./settings/themes";
import AppLocale from "./languageProvider";
import config, {
  getCurrentLanguage
} from "./containers/LanguageSwitcher/config";
import { THEME_CONFIG } from "./settings";
import DashAppHolder from "./dashAppStyle";
import DumpsterStyle from "./assets/dumpster-style";

const currentAppLocale =
  AppLocale[getCurrentLanguage(config.defaultLanguage || "english").locale];

const DashApp = () => (
  <ConfigProvider locale={currentAppLocale.antd}>
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <ThemeProvider theme={themes[THEME_CONFIG.theme]}>
        <DashAppHolder>
          <DumpsterStyle>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <PublicRoutes history={history} />
              </PersistGate>
            </Provider>
          </DumpsterStyle>
        </DashAppHolder>
      </ThemeProvider>
    </IntlProvider>
  </ConfigProvider>
);

export default DashApp;
export { AppLocale };
