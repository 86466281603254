import axios from "axios";
import { APIHost } from "./helpers";

// Base URL
export const baseURL = () => {
  return APIHost() + "/grader/report";
  // return "http://192.168.1.12:3301/grader/report"
};


export const loadPurchaseBillList = data => {
  const url = `${baseURL()}/purchaseBill/load`;
  return axios.post(url, data);
};

export const getPurchaseBillDumpExpress = (id, data) => {
  const url = `${baseURL()}/purchaseBill/express/${id}`;
  return axios.post(url, data);
};

export const getPurchaseBillDumpRecycling = (id, data) => {
  const url = `${baseURL()}/purchaseBill/recycling/${id}`;
  return axios.post(url, data);
};

export const loadStoreReceiptList = data => {
  const url = `${baseURL()}/storeReceipt/load`;
  return axios.post(url, data);
};

export const getStoreReceiptDumpExpress = (id, data) => {
  const url = `${baseURL()}/storeReceipt/express/${id}`;
  return axios.post(url, data);
};

export const getStoreReceiptDumpRecycling = (id, data) => {
  const url = `${baseURL()}/storeReceipt/recycling/${id}`;
  return axios.post(url, data);
};

export const loadWasteReceiptList = data => {
  const url = `${baseURL()}/wasteReceipt/load`;
  return axios.post(url, data);
};

export const getWasteReceiptDumpWaste = (id, data) => {
  const url = `${baseURL()}/wasteReceipt/${id}`;
  return axios.post(url, data);
};


export const loadPurchaseBillMonth = data => {
  const url = `${baseURL()}/summary/purchaseBill/month`;
  return axios.post(url, data);
};

export const getPurchaseBillSummary = (data) => {
  const url = `${baseURL()}/summary/purchaseBill/report`;
  return axios.post(url, data);
};

export const loadStockRecordSummary = data => {
  const url = `${baseURL()}/summary/stockRecord`;
  return axios.post(url, data);
};

export const loadStockRecordList = (data) => {
  const url = `${baseURL()}/summary/stockRecord/list`;
  return axios.post(url, data);
};


export const loadMonthlyStockReport = (data) => {
  const url = `${baseURL()}/monthlyStockReport/load`;
  return axios.post(url, data);
};

export const getMonthlyStockReportDoc = (data) => {
  const url = `${baseURL()}/monthlyStockReport`;
  return axios.post(url, data);
};



export const loadMonthlyWasteReport = (data) => {
  const url = `${baseURL()}/monthlyWasteReport/load`;
  return axios.post(url, data);
};

export const getMonthlyWasteReportDoc = (data) => {
  const url = `${baseURL()}/monthlyWasteReport`;
  return axios.post(url, data);
};


export const dailySettlementDetail = (data) => {
  const url = `${baseURL()}/dailySettlement/detail`;
  return axios.post(url, data);
};

export const completeDailySettlement = (data) => {
  const url = `${baseURL()}/dailySettlement/updClose`;
  return axios.post(url, data);
};
