/* ===== APP ===== */
export const COLLPSE_CHANGE = "COLLPSE_CHANGE";
export const COLLPSE_OPEN_DRAWER = "COLLPSE_OPEN_DRAWER";
export const CHANGE_OPEN_KEYS = "CHANGE_OPEN_KEYS";
export const TOGGLE_ALL = "TOGGLE_ALL";
export const CHANGE_CURRENT = "CHANGE_CURRENT";
export const CLOSE_ALL = "CLOSE_ALL";

export const SET_ENVIRONMENT = "SET_ENVIRONMENT";
export const SET_ENVIRONMENT_SUCCESS = "SET_ENVIRONMENT_SUCCESS";
export const SET_ENVIRONMENT_FAIL = "SET_ENVIRONMENT_FAIL";

export const RESET_APP_STATE = "RESET_APP_STATE";
export const NO_INTERNET = "NO_INTERNET";
export const ENABLE_LOADING = "ENABLE_LOADING";
export const DISABLE_LOADING = "DISABLE_LOADING";

export const TOAST_SUCCESS = "TOAST_SUCCESS";
export const TOAST_ERROR = "TOAST_ERROR";

export const MSG_SUCCESS = "MSG_SUCCESS";
export const MSG_ERROR = "MSG_ERROR";
export const MSG_WARNING = "MSG_WARNING";

export const LOAD_SECURE_IMAGE = "LOAD_SECURE_IMAGE";
export const LOAD_SECURE_IMAGE_SUCCESS = "LOAD_SECURE_IMAGE_SUCCESS";

export const HANDLE_API_ERROR = "HANDLE_API_ERROR";
export const FIELD_ERROR_HANDLE = "FIELD_ERROR_HANDLE";

/* ===== END APP ===== */

/* ===== AUTH ===== */
export const SET_DEVICE_UUID = "SET_DEVICE_UUID";

export const SET_UNDER_MAINTENANCE = "SET_UNDER_MAINTENANCE";
export const PROCEED_SIGNUP = "PROCEED_SIGNUP";
export const PROCEED_FIRST_LOGIN = "PROCEED_FIRST_LOGIN";
export const PROCEED_SIGNUP_UPLOAD_DOC = "PROCEED_SIGNUP_UPLOAD_DOC";

export const DEVICE_REGISTER = "DEVICE_REGISTER";
export const DEVICE_REGISTER_SUCCESS = "DEVICE_REGISTER_SUCCESS";
export const DEVICE_REGISTER_FAIL = "DEVICE_REGISTER_FAIL";

export const SIGNUP = "SIGNUP";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";

export const COMPLETE_SIGNUP = "COMPLETE_SIGNUP";
export const COMPLETE_SIGNUP_SUCCESS = "COMPLETE_SIGNUP_SUCCESS";
export const COMPLETE_SIGNUP_FAIL = "COMPLETE_SIGNUP_FAIL";

export const DISMISS_REGISTER = "DISMISS_REGISTER";
export const DISMISS_REGISTER_SUCCESS = "DISMISS_REGISTER_SUCCESS";

export const DISMISS_FIRST_LOGIN = "DISMISS_FIRST_LOGIN";
export const DISMISS_FIRST_LOGIN_SUCCESS = "DISMISS_FIRST_LOGIN_SUCCESS";

export const CLEAR_FIRST_LOGIN_ERRMSG = "CLEAR_FIRST_LOGIN_ERRMSG";
export const CLEAR_FIRST_LOGIN_ERRMSG_SUCCESS = "CLEAR_FIRST_LOGIN_ERRMSG_SUCCESS";

export const SEND_SMS = "SEND_SMS";
export const SEND_SMS_SUCCESS = "SEND_SMS_SUCCESS";
export const SEND_SMS_FAIL = "SEND_SMS_FAIL";

export const VERIFY_SMS = "VERIFY_SMS";
export const VERIFY_SMS_SUCCESS = "VERIFY_SMS_SUCCESS";
export const VERIFY_SMS_FAIL = "VERIFY_SMS_FAIL";
export const VERIFY_SMS_PENDING_APPROVAL = "VERIFY_SMS_PENDING_APPROVAL";

export const FIRST_LOGIN = "FIRST_LOGIN";
export const FIRST_LOGIN_SUCCESS = "FIRST_LOGIN_SUCCESS";
export const FIRST_LOGIN_FAIL = "FIRST_LOGIN_FAIL";

export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const VALIDATE_VERSION = "VALIDATE_VERSION";
export const VALIDATE_VERSION_SUCCESS = "VALIDATE_VERSION_SUCCESS";
export const VALIDATE_VERSION_FAIL = "VALIDATE_VERSION_FAIL";

export const VERIFY_ACCOUNT = "VERIFY_ACCOUNT";
export const VERIFY_ACCOUNT_SUCCESS = "VERIFY_ACCOUNT_SUCCESS";
export const VERIFY_ACCOUNT_FAIL = "VERIFY_ACCOUNT_FAIL";

export const GET_ACCOUNT_VERIFY_STATUS = "GET_ACCOUNT_VERIFY_STATUS";
export const GET_ACCOUNT_VERIFY_STATUS_SUCCESS = "GET_ACCOUNT_VERIFY_STATUS_SUCCESS";
export const GET_ACCOUNT_VERIFY_STATUS_FAIL = "GET_ACCOUNT_VERIFY_STATUS_FAIL";



export const LOGOUT_CONFIRMATION = "LOGOUT_CONFIRMATION";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";

export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAIL = "GET_PROFILE_FAIL";

export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";

export const UPLOAD_PROFILE_PIC = "UPLOAD_PROFILE_PIC";
export const UPLOAD_PROFILE_PIC_SUCCESS = "UPLOAD_PROFILE_PIC_SUCCESS";
export const UPLOAD_PROFILE_PIC_FAIL = "UPLOAD_PROFILE_PIC_FAIL";

export const REMOVE_PROFILE_PIC = "REMOVE_PROFILE_PIC";
export const REMOVE_PROFILE_PIC_SUCCESS = "REMOVE_PROFILE_PIC_SUCCESS";
export const REMOVE_PROFILE_PIC_FAIL = "REMOVE_PROFILE_PIC_FAIL";

export const UPDATE_PHONE = "UPDATE_PHONE";
export const UPDATE_PHONE_SUCCESS = "UPDATE_PHONE_SUCCESS";
export const UPDATE_PHONE_FAIL = "UPDATE_PHONE_FAIL";

export const REFRESH_JWT_TOKEN = "REFRESH_JWT_TOKEN";
export const REFRESH_JWT_TOKEN_SUCCESS = "REFRESH_JWT_TOKEN_SUCCESS";
export const REFRESH_JWT_TOKEN_FAIL = "REFRESH_JWT_TOKEN_FAIL";

export const FORCE_LOGOUT = "FORCE_LOGOUT";
export const FORCE_LOGOUT_SUCCESS = "FORCE_LOGOUT_SUCCESS";


export const CHECK_VERSION = "CHECK_VERSION";
export const CHECK_VERSION_SUCCESS = "CHECK_VERSION_SUCCESS";
export const CHECK_VERSION_FAIL = "CHECK_VERSION_FAIL";

export const VERSION_UPDATE_LIST = "VERSION_UPDATE_LIST";
export const VERSION_UPDATE_LIST_SUCCESS = "VERSION_UPDATE_LIST_SUCCESS";
export const VERSION_UPDATE_LIST_FAIL = "VERSION_UPDATE_LIST_FAIL";
/* ===== END AUTH ===== */

/* ===== GRADER  ===== */

export const CREATE_GRADER = "CREATE_GRADER";
export const CREATE_GRADER_SUCCESS = "CREATE_GRADER_SUCCESS";
export const CREATE_GRADER_FAIL = "CREATE_GRADER_FAIL";

export const UPDATE_GRADER = "UPDATE_GRADER";
export const UPDATE_GRADER_SUCCESS = "UPDATE_GRADER_SUCCESS";
export const UPDATE_GRADER_FAIL = "UPDATE_GRADER_FAIL";

export const UPLOAD_DOCUMENTATION = "UPLOAD_DOCUMENTATION";
export const UPLOAD_DOCUMENTATION_SUCCESS = "UPLOAD_DOCUMENTATION_SUCCESS";
export const UPLOAD_DOCUMENTATION_FAIL = "UPLOAD_DOCUMENTATION_FAIL";

export const LOAD_GRADER_DOCUMENTATION = "LOAD_GRADER_DOCUMENTATION";
export const LOAD_GRADER_DOCUMENTATION_SUCCESS = "LOAD_GRADER_DOCUMENTATION_SUCCESS";
export const LOAD_GRADER_DOCUMENTATION_FAIL = "LOAD_GRADER_DOCUMENTATION_FAIL";

export const LOAD_GRADER_LIST = "LOAD_GRADER_LIST";
export const LOAD_GRADER_LIST_SUCCESS = "LOAD_GRADER_LIST_SUCCESS";
export const LOAD_GRADER_LIST_FAIL = "LOAD_GRADER_LIST_FAIL";

export const LOAD_GRADER_DETAIL = "LOAD_GRADER_DETAIL";
export const LOAD_GRADER_DETAIL_SUCCESS = "LOAD_GRADER_DETAIL_SUCCESS";
export const LOAD_GRADER_DETAIL_FAIL = "LOAD_GRADER_DETAIL_FAIL";

export const REMOVE_GRADER = "REMOVE_GRADER";
export const REMOVE_GRADER_SUCCESS = "REMOVE_GRADER_SUCCESS";
export const REMOVE_GRADER_FAIL = "REMOVE_GRADER_FAIL";

export const LOAD_ANNOUNCEMENT = "LOAD_ANNOUNCEMENT";
export const LOAD_ANNOUNCEMENT_SUCCESS = "LOAD_ANNOUNCEMENT_SUCCESS";
export const LOAD_ANNOUNCEMENT_FAIL = "LOAD_ANNOUNCEMENT_FAIL";

export const SWITCH_LANGUAGE = "SWITCH_LANGUAGE";
export const SWITCH_LANGUAGE_SUCCESS = "SWITCH_LANGUAGE_SUCCESS";
export const SWITCH_LANGUAGE_FAIL = "SWITCH_LANGUAGE_FAIL";

/* ===== END GRADER ===== */



/* ===== GOOGLE PLACE ===== */
export const LOAD_GOOGLE_PLACE_AUTOCOMPLETE = "LOAD_GOOGLE_PLACE_AUTOCOMPLETE";
export const LOAD_GOOGLE_PLACE_AUTOCOMPLETE_SUCCESS = "LOAD_GOOGLE_PLACE_AUTOCOMPLETE_SUCCESS";
export const LOAD_GOOGLE_PLACE_AUTOCOMPLETE_FAIL = "LOAD_GOOGLE_PLACE_AUTOCOMPLETE_FAIL";
/* ===== END GOOGLE PLACE ===== */



/* ===== GENERAL ===== */

export const LOAD_STATE_DISTRICT_LIST = "LOAD_STATE_DISTRICT_LIST";
export const LOAD_STATE_DISTRICT_LIST_SUCCESS = "LOAD_STATE_DISTRICT_LIST_SUCCESS";
export const LOAD_STATE_DISTRICT_LIST_FAIL = "LOAD_STATE_DISTRICT_LIST_FAIL";

export const LOAD_LANGUAGUES = "LOAD_LANGUAGUES";
export const LOAD_LANGUAGUES_SUCCESS = "LOAD_LANGUAGUES_SUCCESS";
export const LOAD_LANGUAGUES_FAIL = "LOAD_LANGUAGUES_FAIL";

export const LOAD_EXPRESS_RECYCLING_LIST = "LOAD_EXPRESS_RECYCLING_LIST";
export const LOAD_EXPRESS_RECYCLING_LIST_SUCCESS = "LOAD_EXPRESS_RECYCLING_LIST_SUCCESS";
export const LOAD_EXPRESS_RECYCLING_LIST_FAIL = "LOAD_EXPRESS_RECYCLING_LIST_FAIL";

export const LOAD_RECYCLING_LIST = "LOAD_RECYCLING_LIST";
export const LOAD_RECYCLING_LIST_SUCCESS = "LOAD_RECYCLING_LIST_SUCCESS";
export const LOAD_RECYCLING_LIST_FAIL = "LOAD_RECYCLING_LIST_FAIL";

export const LOAD_RECYCLING_CATEGORY = "LOAD_RECYCLING_CATEGORY";
export const LOAD_RECYCLING_CATEGORY_SUCCESS = "LOAD_RECYCLING_CATEGORY_SUCCESS";
export const LOAD_RECYCLING_CATEGORY_FAIL = "LOAD_RECYCLING_CATEGORY_FAIL";

export const LOAD_RECYCLING_EXTRA_FEE = "LOAD_RECYCLING_EXTRA_FEE";
export const LOAD_RECYCLING_EXTRA_FEE_SUCCESS = "LOAD_RECYCLING_EXTRA_FEE_SUCCESS";
export const LOAD_RECYCLING_EXTRA_FEE_FAIL = "LOAD_RECYCLING_EXTRA_FEE_FAIL";

export const LOAD_RECYCLING_DMPSTER = "LOAD_RECYCLING_DMPSTER";
export const LOAD_RECYCLING_DMPSTER_SUCCESS = "LOAD_RECYCLING_DMPSTER_SUCCESS";
export const LOAD_RECYCLING_DMPSTER_FAIL = "LOAD_RECYCLING_DMPSTER_FAIL";

export const LOAD_DMPSTER_SERVICE = "LOAD_DMPSTER_SERVICE";
export const LOAD_DMPSTER_SERVICE_SUCCESS = "LOAD_DMPSTER_SERVICE_SUCCESS";
export const LOAD_DMPSTER_SERVICE_FAIL = "LOAD_DMPSTER_SERVICE_FAIL";

/* ===== END GENERAL ===== */



/* ===== ZONE  ===== */

export const CREATE_ZONE = "CREATE_ZONE";
export const CREATE_ZONE_SUCCESS = "CREATE_ZONE_SUCCESS";
export const CREATE_ZONE_FAIL = "CREATE_ZONE_FAIL";

export const UPDATE_ZONE = "UPDATE_ZONE";
export const UPDATE_ZONE_SUCCESS = "UPDATE_ZONE_SUCCESS";
export const UPDATE_ZONE_FAIL = "UPDATE_ZONE_FAIL";

export const LOAD_ZONE_COUNT = "LOAD_ZONE_COUNT";
export const LOAD_ZONE_COUNT_SUCCESS = "LOAD_ZONE_COUNT_SUCCESS";
export const LOAD_ZONE_COUNT_FAIL = "LOAD_ZONE_COUNT_FAIL";

export const LOAD_ZONE_LIST = "LOAD_ZONE_LIST";
export const LOAD_ZONE_LIST_SUCCESS = "LOAD_ZONE_LIST_SUCCESS";
export const LOAD_ZONE_LIST_FAIL = "LOAD_ZONE_LIST_FAIL";

export const LOAD_ZONE_DETAIL = "LOAD_ZONE_DETAIL";
export const LOAD_ZONE_DETAIL_SUCCESS = "LOAD_ZONE_DETAIL_SUCCESS";
export const LOAD_ZONE_DETAIL_FAIL = "LOAD_ZONE_DETAIL_FAIL";

export const LOAD_ZONE_CURRENT_STOCK_DR = "LOAD_ZONE_CURRENT_STOCK_DR";
export const LOAD_ZONE_CURRENT_STOCK_DR_SUCCESS = "LOAD_ZONE_CURRENT_STOCK_DR_SUCCESS";
export const LOAD_ZONE_CURRENT_STOCK_DR_FAIL = "LOAD_ZONE_CURRENT_STOCK_DR_FAIL";

export const REMOVE_ZONE = "REMOVE_ZONE";
export const REMOVE_ZONE_SUCCESS = "REMOVE_ZONE_SUCCESS";
export const REMOVE_ZONE_FAIL = "REMOVE_ZONE_FAIL";

export const UPDATE_ZONE_PLACEMENT = "UPDATE_ZONE_PLACEMENT";
export const UPDATE_ZONE_PLACEMENT_SUCCESS = "UPDATE_ZONE_PLACEMENT_SUCCESS";
export const UPDATE_ZONE_PLACEMENT_FAIL = "UPDATE_ZONE_PLACEMENT_FAIL";

export const REMOVE_ZONE_PLACEMENT = "REMOVE_ZONE_PLACEMENT";
export const REMOVE_ZONE_PLACEMENT_SUCCESS = "REMOVE_ZONE_PLACEMENT_SUCCESS";
export const REMOVE_ZONE_PLACEMENT_FAIL = "REMOVE_ZONE_PLACEMENT_FAIL";

export const UPDATE_ZONE_FULLY_FILLED = "UPDATE_ZONE_FULLY_FILLED";
export const UPDATE_ZONE_FULLY_FILLED_SUCCESS = "UPDATE_ZONE_FULLY_FILLED_SUCCESS";
export const UPDATE_ZONE_FULLY_FILLED_FAIL = "UPDATE_ZUPDATE_ZONE_FULLY_FILLED_FAILONE_PLACEMENT_FAIL";

export const LOAD_EXPRESS_DUMPSTER_RECYCLING_LIST = "LOAD_EXPRESS_DUMPSTER_RECYCLING_LIST";
export const LOAD_EXPRESS_DUMPSTER_RECYCLING_LIST_SUCCESS = "LOAD_EXPRESS_DUMPSTER_RECYCLING_LIST_SUCCESS";
export const LOAD_EXPRESS_DUMPSTER_RECYCLING_LIST_FAIL = "LOAD_EXPRESS_DUMPSTER_RECYCLING_LIST_FAIL";

export const UPDATE_ZONE_COLLECTION = "UPDATE_ZONE_COLLECTION";
export const UPDATE_ZONE_COLLECTION_SUCCESS = "UPDATE_ZONE_COLLECTION_SUCCESS";
export const UPDATE_ZONE_COLLECTION_FAIL = "UPDATE_ZONE_COLLECTION_FAIL";

export const LOAD_DUMPSTER_HISTORY_LIST = "LOAD_DUMPSTER_HISTORY_LIST";
export const LOAD_DUMPSTER_HISTORY_LIST_SUCCESS = "LOAD_DUMPSTER_HISTORY_LIST_SUCCESS";
export const LOAD_DUMPSTER_HISTORY_LIST_FAIL = "LOAD_DUMPSTER_HISTORY_LIST_FAIL";

export const LOAD_EXPRESS_PACKING_LIST = "LOAD_EXPRESS_PACKING_LIST";
export const LOAD_EXPRESS_PACKING_LIST_SUCCESS = "LOAD_EXPRESS_PACKING_LIST_SUCCESS";
export const LOAD_EXPRESS_PACKING_LIST_FAIL = "LOAD_EXPRESS_PACKING_LIST_FAIL";

export const GENERATE_RECYCLING_PACKING_LIST = "GENERATE_RECYCLING_PACKING_LIST";
export const GENERATE_RECYCLING_PACKING_LIST_SUCCESS = "GENERATE_RECYCLING_PACKING_LIST_SUCCESS";
export const GENERATE_RECYCLING_PACKING_LIST_FAIL = "GENERATE_RECYCLING_PACKING_LIST_FAIL";

export const LOAD_RECYCLING_PACKING_HISTORY_LIST = "LOAD_RECYCLING_PACKING_HISTORY_LIST";
export const LOAD_RECYCLING_PACKING_HISTORY_LIST_SUCCESS = "LOAD_RECYCLING_PACKING_HISTORY_LIST_SUCCESS";
export const LOAD_RECYCLING_PACKING_HISTORY_LIST_FAIL = "LOAD_RECYCLING_PACKING_HISTORY_LIST_FAIL";

export const LOAD_RECYCLING_PACKING_HISTORY_DETAIL = "LOAD_RECYCLING_PACKING_HISTORY_DETAIL";
export const LOAD_RECYCLING_PACKING_HISTORY_DETAIL_SUCCESS = "LOAD_RECYCLING_PACKING_HISTORY_DETAIL_SUCCESS";
export const LOAD_RECYCLING_PACKING_HISTORY_DETAIL_FAIL = "LOAD_RECYCLING_PACKING_HISTORY_DETAIL_FAIL";


/* ===== END ZONE ===== */


/* ===== ORDER  ===== */
export const GET_SETTLEMENT_STATUS = "GET_SETTLEMENT_STATUS";
export const GET_SETTLEMENT_STATUS_SUCCESS = "GET_SETTLEMENT_STATUS_SUCCESS";
export const GET_SETTLEMENT_STATUS_FAIL = "GET_SETTLEMENT_STATUS_FAIL";

export const SCAN_USER_QR = "SCAN_USER_QR";
export const SCAN_USER_QR_SUCCESS = "SCAN_USER_QR_SUCCESS";
export const SCAN_USER_QR_FAIL = "SCAN_USER_QR_FAIL";

export const START_GRADING = "START_GRADING";
export const START_GRADING_SUCCESS = "START_GRADING_SUCCESS";
export const START_GRADING_FAIL = "START_GRADING_FAIL";

export const PLACE_WALKIN_ORDER = "PLACE_WALKIN_ORDER";
export const PLACE_WALKIN_ORDER_SUCCESS = "PLACE_WALKIN_ORDER_SUCCESS";
export const PLACE_WALKIN_ORDER_FAIL = "PLACE_WALKIN_ORDER_FAIL";

export const GET_PENDING_WALKIN_ORDER = "GET_PENDING_WALKIN_ORDER";
export const GET_PENDING_WALKIN_ORDER_SUCCESS = "GET_PENDING_WALKIN_ORDER_SUCCESS";
export const GET_PENDING_WALKIN_ORDER_FAIL = "GET_PENDING_WALKIN_ORDER_FAIL";

export const CANCEL_WALKIN_ORDER = "CANCEL_WALKIN_ORDER";
export const CANCEL_WALKIN_ORDER_SUCCESS = "CANCEL_WALKIN_ORDER_SUCCESS";
export const CANCEL_WALKIN_ORDER_FAIL = "CANCEL_WALKIN_ORDER_FAIL";

export const COMPLETE_WALKIN_ORDER = "COMPLETE_WALKIN_ORDER";
export const COMPLETE_WALKIN_ORDER_SUCCESS = "COMPLETE_WALKIN_ORDER_SUCCESS";
export const COMPLETE_WALKIN_ORDER_FAIL = "COMPLETE_WALKIN_ORDER_FAIL";

export const GRADE_AGAIN_COMPLETED_WALKIN_ORDER = "GRADE_AGAIN_COMPLETED_WALKIN_ORDER";
export const GRADE_AGAIN_COMPLETED_WALKIN_ORDER_SUCCESS = "GRADE_AGAIN_COMPLETED_WALKIN_ORDER_SUCCESS";
export const GRADE_AGAIN_COMPLETED_WALKIN_ORDER_FAIL = "GRADE_AGAIN_COMPLETED_WALKIN_ORDER_FAIL";

export const CANCEL_COMPLETED_WALKIN_ORDER = "CANCEL_COMPLETED_WALKIN_ORDER";
export const CANCEL_COMPLETED_WALKIN_ORDER_SUCCESS = "CANCEL_COMPLETED_WALKIN_ORDER_SUCCESS";
export const CANCEL_COMPLETED_WALKIN_ORDER_FAIL = "CANCEL_COMPLETED_WALKIN_ORDER_FAIL";

export const WALKIN_ORDER_DETAIL = "WALKIN_ORDER_DETAIL";
export const WALKIN_ORDER_DETAIL_SUCCESS = "WALKIN_ORDER_DETAIL_SUCCESS";
export const WALKIN_ORDER_DETAIL_FAIL = "WALKIN_ORDER_DETAIL_FAIL";

export const WALKIN_ORDER_RECYCLING_ADD = "WALKIN_ORDER_RECYCLING_ADD";
export const WALKIN_ORDER_RECYCLING_ADD_SUCCESS = "WALKIN_ORDER_RECYCLING_ADD_SUCCESS";
export const WALKIN_ORDER_RECYCLING_ADD_FAIL = "WALKIN_ORDER_RECYCLING_ADD_FAIL";

export const WALKIN_ORDER_RECYCLING_UPDATE = "WALKIN_ORDER_RECYCLING_UPDATE";
export const WALKIN_ORDER_RECYCLING_UPDATE_SUCCESS = "WALKIN_ORDER_RECYCLING_UPDATE_SUCCESS";
export const WALKIN_ORDER_RECYCLING_UPDATE_FAIL = "WALKIN_ORDER_RECYCLING_UPDATE_FAIL";

export const WALKIN_ORDER_RECYCLING_PRICE_UPD_CONFIRM = "WALKIN_ORDER_RECYCLING_PRICE_UPD_CONFIRM";

export const WALKIN_ORDER_RECYCLING_REMOVE = "WALKIN_ORDER_RECYCLING_REMOVE";
export const WALKIN_ORDER_RECYCLING_REMOVE_SUCCESS = "WALKIN_ORDER_RECYCLING_REMOVE_SUCCESS";
export const WALKIN_ORDER_RECYCLING_REMOVE_FAIL = "WALKIN_ORDER_RECYCLING_REMOVE_FAIL";

export const WALKIN_ORDER_SET_ZONE = "WALKIN_ORDER_SET_ZONE";
export const WALKIN_ORDER_SET_ZONE_SUCCESS = "WALKIN_ORDER_SET_ZONE_SUCCESS";
export const WALKIN_ORDER_SET_ZONE_FAIL = "WALKIN_ORDER_SET_ZONE_FAIL";

export const WALKIN_ORDER_CATEGORY_SET_ZONE = "WALKIN_ORDER_CATEGORY_SET_ZONE";
export const WALKIN_ORDER_CATEGORY_SET_ZONE_SUCCESS = "WALKIN_ORDER_CATEGORY_SET_ZONE_SUCCESS";
export const WALKIN_ORDER_CATEGORY_SET_ZONE_FAIL = "WALKIN_ORDER_CATEGORY_SET_ZONE_FAIL";

export const ORDER_HISTORY_COUNT = "ORDER_HISTORY_COUNT";
export const ORDER_HISTORY_COUNT_SUCCESS = "ORDER_HISTORY_COUNT_SUCCESS";
export const ORDER_HISTORY_COUNT_FAIL = "ORDER_HISTORY_COUNT_FAIL";

export const ORDER_HISTORY_LIST = "ORDER_HISTORY_LIST";
export const ORDER_HISTORY_LIST_SUCCESS = "ORDER_HISTORY_LIST_SUCCESS";
export const ORDER_HISTORY_LIST_FAIL = "ORDER_HISTORY_LIST_FAIL";

export const ORDER_HISTORY_DETAIL = "ORDER_HISTORY_DETAIL";
export const ORDER_HISTORY_DETAIL_SUCCESS = "ORDER_HISTORY_DETAIL_SUCCESS";
export const ORDER_HISTORY_DETAIL_FAIL = "ORDER_HISTORY_DETAIL_FAIL";

export const GRADING_TASK_LIST = "GRADING_TASK_LIST";
export const GRADING_TASK_LIST_SUCCESS = "GRADING_TASK_LIST_SUCCESS";
export const GRADING_TASK_LIST_FAIL = "GRADING_TASK_LIST_FAIL";

export const GRADING_TASK_DETAIL = "GRADING_TASK_DETAIL";
export const GRADING_TASK_DETAIL_SUCCESS = "GRADING_TASK_DETAIL_SUCCESS";
export const GRADING_TASK_DETAIL_FAIL = "GRADING_TASK_DETAIL_FAIL";

export const GRADING_TASK_ACCEPT = "GRADING_TASK_ACCEPT";
export const GRADING_TASK_ACCEPT_SUCCESS = "GRADING_TASK_ACCEPT_SUCCESS";
export const GRADING_TASK_ACCEPT_FAIL = "GRADING_TASK_ACCEPT_FAIL";

export const GRADING_TASK_REJECT = "GRADING_TASK_REJECT";
export const GRADING_TASK_REJECT_SUCCESS = "GRADING_TASK_REJECT_SUCCESS";
export const GRADING_TASK_REJECT_FAIL = "GRADING_TASK_REJECT_FAIL";

export const GRADING_TASK_SET_ZONE = "GRADING_TASK_SET_ZONE";
export const GRADING_TASK_SET_ZONE_SUCCESS = "GRADING_TASK_SET_ZONE_SUCCESS";
export const GRADING_TASK_SET_ZONE_FAIL = "GRADING_TASK_SET_ZONE_FAIL";

export const GRADING_TASK_CATEGORY_SET_ZONE = "GRADING_TASK_CATEGORY_SET_ZONE";
export const GRADING_TASK_CATEGORY_SET_ZONE_SUCCESS = "GRADING_TASK_CATEGORY_SET_ZONE_SUCCESS";
export const GRADING_TASK_CATEGORY_SET_ZONE_FAIL = "GRADING_TASK_CATEGORY_SET_ZONE_FAIL";

export const GRADING_TASK_RECYCLING_ADD = "GRADING_TASK_RECYCLING_ADD";
export const GRADING_TASK_RECYCLING_ADD_SUCCESS = "GRADING_TASK_RECYCLING_ADD_SUCCESS";
export const GRADING_TASK_RECYCLING_ADD_FAIL = "GRADING_TASK_RECYCLING_ADD_FAIL";

export const GRADING_TASK_RECYCLING_PRICE_UPD_CONFIRM = "GRADING_TASK_RECYCLING_PRICE_UPD_CONFIRM";

export const GRADING_TASK_RECYCLING_UPDATE = "GRADING_TASK_RECYCLING_UPDATE";
export const GRADING_TASK_RECYCLING_UPDATE_SUCCESS = "GRADING_TASK_RECYCLING_UPDATE_SUCCESS";
export const GRADING_TASK_RECYCLING_UPDATE_FAIL = "GRADING_TASK_RECYCLING_UPDATE_FAIL";

export const GRADING_TASK_RECYCLING_REMOVE = "GRADING_TASK_RECYCLING_REMOVE";
export const GRADING_TASK_RECYCLING_REMOVE_SUCCESS = "GRADING_TASK_RECYCLING_REMOVE_SUCCESS";
export const GRADING_TASK_RECYCLING_REMOVE_FAIL = "GRADING_TASK_RECYCLING_REMOVE_FAIL";

export const GRADING_TASK_UPLOAD_DOC = "GRADING_TASK_UPLOAD_DOC";
export const GRADING_TASK_UPLOAD_DOC_SUCCESS = "GRADING_TASK_UPLOAD_DOC_SUCCESS";
export const GRADING_TASK_UPLOAD_DOC_FAIL = "GRADING_TASK_UPLOAD_DOC_FAIL";

export const COMPLETE_GRADING_TASK = "COMPLETE_GRADING_TASK";
export const COMPLETE_GRADING_TASK_SUCCESS = "COMPLETE_GRADING_TASK_SUCCESS";
export const COMPLETE_GRADING_TASK_FAIL = "COMPLETE_GRADING_TASK_FAIL";

export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAIL = "REGISTER_USER_FAIL";

export const CHECK_USER_EXIST = "CHECK_USER_EXIST";
export const CHECK_USER_EXIST_SUCCESS = "CHECK_USER_EXIST_SUCCESS";
export const CHECK_USER_EXIST_FAIL = "CHECK_USER_EXIST_FAIL";

export const RESET_USER_EXIST = "RESET_USER_EXIST";
export const RESET_USER_EXIST_SUCCESS = "RESET_USER_EXIST_SUCCESS";


export const WASTE_GRADING_TASK_LIST = "WASTE_GRADING_TASK_LIST";
export const WASTE_GRADING_TASK_LIST_SUCCESS = "WASTE_GRADING_TASK_LIST_SUCCESS";
export const WASTE_GRADING_TASK_LIST_FAIL = "WASTE_GRADING_TASK_LIST_FAIL";

export const WASTE_GRADING_TASK_DETAIL = "WASTE_GRADING_TASK_DETAIL";
export const WASTE_GRADING_TASK_DETAIL_SUCCESS = "WASTE_GRADING_TASK_DETAIL_SUCCESS";
export const WASTE_GRADING_TASK_DETAIL_FAIL = "WASTE_GRADING_TASK_DETAIL_FAIL";

export const WASTE_GRADING_TASK_UPLOAD_DOC = "WASTE_GRADING_TASK_UPLOAD_DOC";
export const WASTE_GRADING_TASK_UPLOAD_DOC_SUCCESS = "WASTE_GRADING_TASK_UPLOAD_DOC_SUCCESS";
export const WASTE_GRADING_TASK_UPLOAD_DOC_FAIL = "WASTE_GRADING_TASK_UPLOAD_DOC_FAIL";

export const COMPLETE_WASTE_GRADING_TASK = "COMPLETE_WASTE_GRADING_TASK";
export const COMPLETE_WASTE_GRADING_TASK_SUCCESS = "COMPLETE_WASTE_GRADING_TASK_SUCCESS";
export const COMPLETE_WASTE_GRADING_TASK_FAIL = "COMPLETE_WASTE_GRADING_TASK_FAIL";

/* ===== END ORDER ===== */



/* ===== REPORT  ===== */

export const LOAD_PURCHASE_BILL_LIST = "LOAD_PURCHASE_BILL_LIST";
export const LOAD_PURCHASE_BILL_LIST_SUCCESS = "LOAD_PURCHASE_BILL_LIST_SUCCESS";
export const LOAD_PURCHASE_BILL_LIST_FAIL = "LOAD_PURCHASE_BILL_LIST_FAIL";

export const GET_PURCHASE_BILL_DUMP_EXPRESS = "GET_PURCHASE_BILL_DUMP_EXPRESS";
export const GET_PURCHASE_BILL_DUMP_EXPRESS_SUCCESS = "GET_PURCHASE_BILL_DUMP_EXPRESS_SUCCESS";
export const GET_PURCHASE_BILL_DUMP_EXPRESS_FAIL = "GET_PURCHASE_BILL_DUMP_EXPRESS_FAIL";

export const GET_PURCHASE_BILL_DUMP_RECYCLING = "GET_PURCHASE_BILL_DUMP_RECYCLING";
export const GET_PURCHASE_BILL_DUMP_RECYCLING_SUCCESS = "GET_PURCHASE_BILL_DUMP_RECYCLING_SUCCESS";
export const GET_PURCHASE_BILL_DUMP_RECYCLING_FAIL = "GET_PURCHASE_BILL_DUMP_RECYCLING_FAIL";

export const LOAD_STORE_RECEIPT_LIST = "LOAD_STORE_RECEIPT_LIST";
export const LOAD_STORE_RECEIPT_LIST_SUCCESS = "LOAD_STORE_RECEIPT_LIST_SUCCESS";
export const LOAD_STORE_RECEIPT_LIST_FAIL = "LOAD_STORE_RECEIPT_LIST_FAIL";

export const GET_STORE_RECEIPT_DUMP_EXPRESS = "GET_STORE_RECEIPT_DUMP_EXPRESS";
export const GET_STORE_RECEIPT_DUMP_EXPRESS_SUCCESS = "GET_STORE_RECEIPT_DUMP_EXPRESS_SUCCESS";
export const GET_STORE_RECEIPT_DUMP_EXPRESS_FAIL = "GET_STORE_RECEIPT_DUMP_EXPRESS_FAIL";

export const GET_STORE_RECEIPT_DUMP_RECYCLING = "GET_STORE_RECEIPT_DUMP_RECYCLING";
export const GET_STORE_RECEIPT_DUMP_RECYCLING_SUCCESS = "GET_STORE_RECEIPT_DUMP_RECYCLING_SUCCESS";
export const GET_STORE_RECEIPT_DUMP_RECYCLING_FAIL = "GET_STORE_RECEIPT_DUMP_RECYCLING_FAIL";

export const LOAD_WASTE_RECEIPT_LIST = "LOAD_WASTE_RECEIPT_LIST";
export const LOAD_WASTE_RECEIPT_LIST_SUCCESS = "LOAD_WASTE_RECEIPT_LIST_SUCCESS";
export const LOAD_WASTE_RECEIPT_LIST_FAIL = "LOAD_WASTE_RECEIPT_LIST_FAIL";

export const GET_WASTE_RECEIPT_DUMP_WASTE = "GET_WASTE_RECEIPT_DUMP_WASTE";
export const GET_WASTE_RECEIPT_DUMP_WASTE_SUCCESS = "GET_WASTE_RECEIPT_DUMP_WASTE_SUCCESS";
export const GET_WASTE_RECEIPT_DUMP_WASTE_FAIL = "GET_WASTE_RECEIPT_DUMP_WASTE_FAIL";


export const LOAD_PURCHASE_BILL_MONTH = "LOAD_PURCHASE_BILL_MONTH";
export const LOAD_PURCHASE_BILL_MONTH_SUCCESS = "LOAD_PURCHASE_BILL_MONTH_SUCCESS";
export const LOAD_PURCHASE_BILL_MONTH_FAIL = "LOAD_PURCHASE_BILL_MONTH_FAIL";

export const LOAD_PURCHASE_BILL_SUMMARY = "LOAD_PURCHASE_BILL_SUMMARY";
export const LOAD_PURCHASE_BILL_SUMMARY_SUCCESS = "LOAD_PURCHASE_BILL_SUMMARY_SUCCESS";
export const LOAD_PURCHASE_BILL_SUMMARY_FAIL = "LOAD_PURCHASE_BILL_SUMMARY_FAIL";


export const LOAD_STOCK_RECORD_SUMMARY = "LOAD_STOCK_RECORD_SUMMARY";
export const LOAD_STOCK_RECORD_SUMMARY_SUCCESS = "LOAD_STOCK_RECORD_SUMMARY_SUCCESS";
export const LOAD_STOCK_RECORD_SUMMARY_FAIL = "LOAD_STOCK_RECORD_SUMMARY_FAIL";

export const LOAD_STOCK_RECORD_LIST = "LOAD_STOCK_RECORD_LIST";
export const LOAD_STOCK_RECORD_LIST_SUCCESS = "LOAD_STOCK_RECORD_LIST_SUCCESS";
export const LOAD_STOCK_RECORD_LIST_FAIL = "LOAD_STOCK_RECORD_LIST_FAIL";


export const LOAD_MONTHLY_STOCK_REPORT = "LOAD_MONTHLY_STOCK_REPORT";
export const LOAD_MONTHLY_STOCK_REPORT_SUCCESS = "LOAD_MONTHLY_STOCK_REPORT_SUCCESS";
export const LOAD_MONTHLY_STOCK_REPORT_FAIL = "LOAD_MONTHLY_STOCK_REPORT_FAIL";

export const GET_MONTHLY_STOCK_REPORT_DOC = "GET_MONTHLY_STOCK_REPORT_DOC";
export const GET_MONTHLY_STOCK_REPORT_DOC_SUCCESS = "GET_MONTHLY_STOCK_REPORT_DOC_SUCCESS";
export const GET_MONTHLY_STOCK_REPORT_DOC_FAIL = "GET_MONTHLY_STOCK_REPORT_DOC_FAIL";


export const LOAD_MONTHLY_WASTE_REPORT = "LOAD_MONTHLY_WASTE_REPORT";
export const LOAD_MONTHLY_WASTE_REPORT_SUCCESS = "LOAD_MONTHLY_WASTE_REPORT_SUCCESS";
export const LOAD_MONTHLY_WASTE_REPORT_FAIL = "LOAD_MONTHLY_WASTE_REPORT_FAIL";

export const GET_MONTHLY_WASTE_REPORT_DOC = "GET_MONTHLY_WASTE_REPORT_DOC";
export const GET_MONTHLY_WASTE_REPORT_DOC_SUCCESS = "GET_MONTHLY_WASTE_REPORT_DOC_SUCCESS";
export const GET_MONTHLY_WASTE_REPORT_DOC_FAIL = "GET_MONTHLY_WASTE_REPORT_DOC_FAIL";


export const LOAD_DAILY_SETTLEMENT_DETAIL = "LOAD_DAILY_SETTLEMENT_DETAIL";
export const LOAD_DAILY_SETTLEMENT_DETAIL_SUCCESS = "LOAD_DAILY_SETTLEMENT_DETAIL_SUCCESS";
export const LOAD_DAILY_SETTLEMENT_DETAIL_FAIL = "LOAD_DAILY_SETTLEMENT_DETAIL_FAIL";

export const COMPLETE_DAILY_SETTLEMENT = "COMPLETE_DAILY_SETTLEMENT";
export const COMPLETE_DAILY_SETTLEMENT_SUCCESS = "COMPLETE_DAILY_SETTLEMENT_SUCCESS";
export const COMPLETE_DAILY_SETTLEMENT_FAIL = "COMPLETE_DAILY_SETTLEMENT_FAIL";
/* ===== END REPORT ===== */