import * as actionTypes from "../actions/actionTypes";

const initState = {
  stateDistrictList: [],
  isStateDistrictList: false,

  languagesList: [],
  isLoadinglanguagesList: false,

  recyclingList: [],
  isLoadingRecyclingList: false,

  expressRecyclingList: [],
  isLoadingExpressRecyclingList: false, 
  
  recyclingCategoryList: [],
  isLoadingRecyclingCategoryList: false,

  recyclingExtraFee: [],
  isLoadingRecyclingExtraFee: false,

  recyclingDumpsterList: [],
  isLoadingRecyclingDumpsterList: false,

  dumpsterServiceList: [],
  isLoadingDumpsterServiceList: false,
};

export default function generalReducer(state = initState, action) {
 
  switch (action.type) {
   
    case actionTypes.LOAD_STATE_DISTRICT_LIST: {
      return {
        ...state,
        stateDistrictList: [],
        isStateDistrictList: true,
      };
    }

    case actionTypes.LOAD_STATE_DISTRICT_LIST_SUCCESS: {
      return {
        ...state,
        stateDistrictList: action.data,
        isStateDistrictList: false,

      };
    }

    case actionTypes.LOAD_STATE_DISTRICT_LIST_FAIL: {
      return {
        ...state,
        isStateDistrictList: false,

      };
    }
    
    
    case actionTypes.LOAD_LANGUAGUES: {
      return {
        ...state,
        languagesList: [],
        isLoadinglanguagesList: true,
      };
    }

    case actionTypes.LOAD_LANGUAGUES_SUCCESS: {
      return {
        ...state,
        languagesList: action.data,
        isLoadinglanguagesList: false,

      };
    }

    case actionTypes.LOAD_LANGUAGUES_FAIL: {
      return {
        ...state,
        isLoadinglanguagesList: false,

      };
    }
    
    
    
    case actionTypes.LOAD_EXPRESS_RECYCLING_LIST: {
      return {
        ...state,
        expressRecyclingList: [],
        isLoadingExpressRecyclingList: true,   
      };
    }

    case actionTypes.LOAD_EXPRESS_RECYCLING_LIST_SUCCESS: {

      return {
        ...state,
        expressRecyclingList: action.data,
        isLoadingExpressRecyclingList: false,
      };
    }

    case actionTypes.LOAD_EXPRESS_RECYCLING_LIST_FAIL: {

      return {
        ...state,
      };
    }


    case actionTypes.LOAD_RECYCLING_LIST: {
      return {
        ...state,
        recyclingList: [],
        isLoadingRecyclingList: true,   
      };
    }

    case actionTypes.LOAD_RECYCLING_LIST_SUCCESS: {

      return {
        ...state,
        recyclingList: action.data,
        isLoadingRecyclingList: false,
      };
    }

    case actionTypes.LOAD_RECYCLING_LIST_FAIL: {

      return {
        ...state,
      };
    }

    
    
    
    case actionTypes.LOAD_RECYCLING_CATEGORY: {
      
      return {
        ...state,
        recyclingCategoryList: [],
        isLoadingRecyclingCategoryList: true,
      };
    }

    case actionTypes.LOAD_RECYCLING_CATEGORY_SUCCESS: {
  
      return {
        ...state,
        recyclingCategoryList: action.data,
        isLoadingRecyclingCategoryList: false,
      };
    }

    case actionTypes.LOAD_RECYCLING_CATEGORY_FAIL: {
      return {
        ...state,
        isLoadingRecyclingCategoryList: false,
      };
    }

    
    
    case actionTypes.LOAD_RECYCLING_EXTRA_FEE: {
      
      return {
        ...state,
        recyclingExtraFee: [],
        isLoadingRecyclingExtraFee: true,
      };
    }

    case actionTypes.LOAD_RECYCLING_EXTRA_FEE_SUCCESS: {
  
      return {
        ...state,
        recyclingExtraFee: action.data,
        isLoadingRecyclingExtraFee: false,
      };
    }

    case actionTypes.LOAD_RECYCLING_EXTRA_FEE_FAIL: {
      return {
        ...state,
        isLoadingRecyclingExtraFee: false,
      };
    }
    
    
    case actionTypes.LOAD_RECYCLING_DMPSTER: {
      
      return {
        ...state,
        recyclingDumpsterList: [],
        isLoadingRecyclingDumpsterList: true,
      };
    }

    case actionTypes.LOAD_RECYCLING_DMPSTER_SUCCESS: {
      
      return {
        ...state,
        recyclingDumpsterList: action.data,
        isLoadingRecyclingDumpsterList: false,
      };
    }

    case actionTypes.LOAD_RECYCLING_DMPSTER_FAIL: {
      return {
        ...state,
        isLoadingRecyclingDumpsterList: false,
      };
    }
    
    case actionTypes.LOAD_DMPSTER_SERVICE: {
      
      return {
        ...state,
        dumpsterServiceList: [],
        isLoadingDumpsterServiceList: true,
      };
    }

    case actionTypes.LOAD_DMPSTER_SERVICE_SUCCESS: {
      
      return {
        ...state,
        dumpsterServiceList: action.data,
        isLoadingDumpsterServiceList: false,
      };
    }

    case actionTypes.LOAD_DMPSTER_SERVICE_FAIL: {
      return {
        ...state,
        isLoadingDumpsterServiceList: false,
      };
    }
    
    default:
      return state;
  }
}
