import React, { Component } from "react";
import { Redirect, Switch, Route, withRouter } from "react-router-dom";
import asyncComponent from "../../helpers/AsyncFunc";

const routes = [
  /* ===== General ===== */
  {
    path: "loading",
    component: asyncComponent(() => import("../Page/LoadingPage"))
  },
  {
    path: "signin",
    component: asyncComponent(() => import("../Page/SignIn"))
  },
  {
    path: "",
    component: asyncComponent(() => import("../Page/Home"))
  },
  {
    path: "blank",
    component: asyncComponent(() => import("../Page/Blank"))
  },
  {
    path: "maintenance",
    component: asyncComponent(() => import("../Page/Blank"))
  },
  {
    path: "register",
    component: asyncComponent(() => import("../Page/Blank"))
  },
  /* ===== END General ===== */

  
  {
    path: "DumpExpress",
    component: asyncComponent(() => import("../Page/DumpExpressGrading"))
  },  
  {
    path: "history",
    component: asyncComponent(() => import("../Page/GradingHistory"))
  },  
  {
    path: "history/express/:id",
    component: asyncComponent(() => import("../Page/DumpExpressHistoryDetail"))
  },
  {
    path: "history/recycling/:id",
    component: asyncComponent(() => import("../Page/DumpRecyclingHistoryDetail"))
  },
  {
    path: "history/waste/:id",
    component: asyncComponent(() => import("../Page/DumpWasteHistoryDetail"))
  },
  {
    path: "walk-in-grading",
    component: asyncComponent(() => import("../Page/WalkInGrading"))
  },
  {
    path: "walk-in-grading/cancel",
    component: asyncComponent(() => import("../Page/WalkInGradingCancelled"))
  },
  {
    path: "grading-task",
    component: asyncComponent(() => import("../Page/GradingTaskListing"))
  },
  {
    path: "grading-task/:id",
    component: asyncComponent(() => import("../Page/GradingTaskDetail"))
  },
  {
    path: "waste-grading-task",
    component: asyncComponent(() => import("../Page/WasteGradingTaskListing"))
  },
  {
    path: "waste-grading-task/:id",
    component: asyncComponent(() => import("../Page/WasteGradingTaskDetail"))
  },
  
  {
    path: "profile",
    component: asyncComponent(() => import("../Page/Profile"))
  },
  {
    path: "profile/org",
    component: asyncComponent(() => import("../Page/RecyclingCenterInfo"))
  },
  {
    path: "account",
    component: asyncComponent(() => import("../Page/AccountSecurity"))
  },
  
  /* ===== Manage Grader ===== */
  {
    path: "grader",
    component: asyncComponent(() => import("../Page/GraderListing"))
  },
  {
    path: "grader/create",
    component: asyncComponent(() => import("../Page/GraderAdd"))
  },
  {
    path: "grader/edit/:id",
    component: asyncComponent(() => import("../Page/GraderEdit"))
  },
  /* ===== END Manage Grader ===== */
  
  {
    path: "registerUser",
    component: asyncComponent(() => import("../Page/RegisterUser"))
  },
  
  /* ===== Manage Zone ===== */
  {
    path: "zone",
    component: asyncComponent(() => import("../Page/ZoneListing"))
  },
  {
    path: "zone/detail/:id",
    component: asyncComponent(() => import("../Page/ZoneDetail"))
  },
  {
    path: "zone/create",
    component: asyncComponent(() => import("../Page/ZoneAdd"))
  },
  {
    path: "zone/edit/:id",
    component: asyncComponent(() => import("../Page/ZoneEdit"))
  },
  {
    path: "zone/dumpsterHistory",
    component: asyncComponent(() => import("../Page/DumpsterHistoryExpress"))
  },
  {
    path: "zone/packingHistory",
    component: asyncComponent(() => import("../Page/PackingHistoryRecycling"))
  },
  {
    path: "zone/expressRecycling/:id",
    component: asyncComponent(() => import("../Page/ZoneExpressRecyclingList"))
  },
  /* ===== END Manage Zone ===== */
  
  /* ===== Manage Report ===== */
  {
    path: "report/purchaseBill",
    component: asyncComponent(() => import("../Page/PurchaseBillListing"))
  },
  {
    path: "report/storeReceipt",
    component: asyncComponent(() => import("../Page/StoreReceiptListing"))
  },
  {
    path: "report/summary/purchaseBill",
    component: asyncComponent(() => import("../Page/PurchaseBillSummary"))
  },
  {
    path: "report/stockRecord",
    component: asyncComponent(() => import("../Page/StockRecord"))
  },
  {
    path: "report/monthlyStockReport",
    component: asyncComponent(() => import("../Page/MonthlyStockReport"))
  },
  {
    path: "report/packingList",
    component: asyncComponent(() => import("../Page/PackingListing"))
  },
  {
    path: "report/dailySettlement",
    component: asyncComponent(() => import("../Page/DailySettlement"))
  },
  {
    path: "report/wasteReceipt",
    component: asyncComponent(() => import("../Page/WasteReceiptListing"))
  },
  {
    path: "report/monthlyWasteReport",
    component: asyncComponent(() => import("../Page/MonthlyWasteReport"))
  },
  
  /* ===== END Manage Report ===== */
];

class AppRouter extends Component {
  render() {
    const { url, style, profileData } = this.props;
    return (
      <div style={style}>
        <Switch>
          {routes.map(data => {
            const { path, exact, ...otherProps } = data;

            let havePermission = true;
            if (data.permission) {
              if (profileData[data.permission] === false) {
                havePermission = false;
              }
            }

            return havePermission ? (
              <Route
                exact={exact === false ? false : true}
                key={data.path}
                path={`${url}${data.path}`}
                {...otherProps}
              />
            ) : null;
          })}
          <Redirect to="/" />
        </Switch>
      </div>
    );
  }
}

export default withRouter(AppRouter);
