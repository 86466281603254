import React from "react";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import { GOOGLE_API_KEY } from "../../../settings";
import { store } from "../../../redux/store";
let env = store.getState().App.env;

/* Props
  coordinates: {lat: 12, long: 123}     // Required
  markerDraggable: {lat: 12, long: 123} // Optional
  onMarkerDragEnd: Func                 // Optional
*/
const MyGoogleMap = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY[env]}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => {
  return (
    <GoogleMap
      defaultZoom={16}
      defaultCenter={props.coordinates}
      center={props.coordinates}
      {...props}
    >
      {props.isMarkerShown !== false && (
        <Marker
          position={props.coordinates}
          onClick={props.onMarkerClick}
          draggable={props.markerDraggable ? props.markerDraggable : false}
          onDragEnd={e => {
            let coordinates = { lat: e.latLng.lat(), lng: e.latLng.lng() };
            console.log(coordinates);
            if(props.onMarkerDragEnd) {
              props.onMarkerDragEnd(coordinates)
            }
          }}
        />
      )}
    </GoogleMap>
  );
});

export default MyGoogleMap;
