import { all } from "redux-saga/effects";
import authSagas from "./auth_saga";
import appSagas from "./app_saga";
import graderSagas from "./grader_sage";
import googleMapSaga from "./google_map_saga";
import generalSaga from "./general_saga";
import zoneSaga from "./zone_sage";
import orderSaga from "./order_sage";
import reportSaga from "./report_sage";

export default function* rootSaga() {
  yield all([
    appSagas(), 
    authSagas(), 
    graderSagas(),
    googleMapSaga(),
    generalSaga(),
    zoneSaga(),
    orderSaga(),
    reportSaga(),
  ]);
}
